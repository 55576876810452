import { TContentfulEmployee, TEmployee } from '../_models';

export function serializeContentfulEmployee(employee: TContentfulEmployee): TEmployee {
  if (!employee) return;
  return {
    group: employee.group,
    jobFunction: employee.function,
    mail: employee.mail,
    name: employee.name,
    picture: employee.picture.url,
  };
}
