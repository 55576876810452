import { ApolloClient, InMemoryCache } from '@apollo/client';

export type TEnvironment = 'local' | 'development' | 'test' | 'staging' | 'production';

const nodeEnv = process.env.NODE_ENV;
function assertNodeEnv(env: string | undefined): asserts env {
  if (!env) {
    throw Error('NODE ENV must be specified');
  }
}

assertNodeEnv(nodeEnv);

export class Config {
  static get api(): { apiKey: string; host: string } {
    return { apiKey: process.env.REACT_APP_API_KEY as string, host: process.env.REACT_APP_API_HOST as string };
  }

  static get environment(): TEnvironment {
    return nodeEnv as TEnvironment;
  }

  static get deKleineVosMaps(): string {
    return 'https://goo.gl/maps/i9KTWcqMYLYCyPNx8';
  }

  static get kakelbontMaps(): string {
    return 'https://goo.gl/maps/HzuDiW4P5J4aGySD7';
  }

  static get sentryDsn(): string {
    return process.env.REACT_APP_SENTRY_DSN as string;
  }

  static get apolloClient() {
    return new ApolloClient({
      cache: new InMemoryCache(),
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_DELIVERY_API_KEY}`,
      },
      uri: `${process.env.REACT_APP_CONTENTFUL_GRAPHQL_API_URL}/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}/environments/${process.env.REACT_APP_CONTENTFUL_ENVIRONMENT}/`,
    });
  }

  static get emailjs() {
    return {
      contactTemplateId: process.env.REACT_APP_EMAILJS_CONTACT_TEMPLATE_ID,
      offerTemplateId: process.env.REACT_APP_EMAILJS_OFFER_TEMPLATE_ID,
      publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
      serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    };
  }
}
