export type TEmailType = 'contact' | 'offer';

type TSection = {
  includedFields: string[];
  title: string;
}[];

type TStringObject = Record<string, string>;

// Email content is made up of sections. contact/offer each have their own array of sections
// transformToEmail function = main of this file
// we build a long string that represents the html email content, this string gets translated to real html by emailJS

// 1. Correct SectionArray is picked (contact/offer)
// 2. For each section, the title and fields gets concatenated to the html string (initially = '')

//DATA

const offerSections: TSection = [
  {
    includedFields: [
      'audience',
      'caregiverDepartment',
      'caregiverEmail',
      'caregiverFirstName',
      'caregiverLastName',
      'caregiverPhone',
      'isDiscussed',
    ],
    title: 'Gegevens aanvrager',
  },
  {
    includedFields: ['childFirstName', 'childLastName', 'childBirthdate', 'gender', 'family', 'school', 'grade'],
    title: 'Gegevens kind',
  },
  {
    includedFields: [
      'fatherFirstName',
      'fatherLastName',
      'fatherEmail',
      'fatherPhone',
      'fatherLanguage',
      'fatherStreet',
      'fatherNumber',
      'fatherBus',
      'fatherZip',
    ],
    title: 'Gegevens vader',
  },
  {
    includedFields: [
      'motherFirstName',
      'motherLastName',
      'motherEmail',
      'motherPhone',
      'motherLanguage',
      'motherStreet',
      'motherNumber',
      'motherBus',
      'motherZip',
    ],
    title: 'Gegevens moeder',
  },
  {
    includedFields: [
      'otherFirstName',
      'otherLastName',
      'otherRelation',
      'otherEmail',
      'otherPhone',
      'otherLanguage',
      'otherStreet',
      'otherNumber',
      'otherBus',
      'otherZip',
    ],
    title: 'Gegevens andere persoon',
  },
  {
    includedFields: ['answer1', 'answer2', 'answer3', 'answer4', 'answer5', 'answer6', 'answer7'],
    title: 'Situatie',
  },
];

const contactSections: TSection = [{ includedFields: ['firstName', 'lastName', 'audience', 'email', 'message'], title: '' }];

const offerFieldNameMap: TStringObject = {
  answer1: 'Wat loopt er goed qua opvoeding',
  answer2: 'Welke andere personen zijn er nog betrokken bij het gezin',
  answer3: 'Wat loopt er minder goed qua veiligheid, opvoeding, ...',
  answer4: 'Wat zou jij graag zien veranderen? Wat verwacht je van onze begeleiding',
  answer5: 'Wat loopt er goed op school',
  answer6: 'Wat loopt er minder goed op school',
  answer7: 'Wat zou jij graag zien veranderen? Wat verwacht je van onze begeleiding',
  audience: 'Rol',
  caregiverDepartment: 'Dienst',
  caregiverEmail: 'Emailadres',
  caregiverFirstName: 'Voornaam',
  caregiverLastName: 'Achternaam',
  caregiverPhone: 'Telefoonnummer',
  childBirthdate: 'Geboortedatum',
  childFirstName: 'Voornaam',
  childLastName: 'Achternaam',
  family: 'Verblijft bij',
  fatherBus: 'Bus',
  fatherEmail: 'Emailadres',
  fatherFirstName: 'Voornaam',
  fatherLanguage: 'Taal',
  fatherLastName: 'Achternaam',
  fatherNumber: 'Huisnummer',
  fatherPhone: 'Telefoonnummer',
  fatherStreet: 'Straat',
  fatherZip: 'Postcode',
  gender: 'Geslacht',
  grade: 'Leerjaar',
  isDiscussed: 'Aanvraag besproken met ouders',
  motherBus: 'Bus',
  motherEmail: 'Emailadres',
  motherFirstName: 'Voornaam',
  motherLanguage: 'Taal',
  motherLastName: 'Achternaam',
  motherNumber: 'Huisnummer',
  motherPhone: 'Telefoonnummer',
  motherStreet: 'Straat',
  motherZip: 'Postcode',
  otherBus: 'Bus',
  otherEmail: 'Emailadres',
  otherFirstName: 'Voornaam',
  otherLanguage: 'Taal',
  otherLastName: 'Achternaam',
  otherNumber: 'Telefoonnummer',
  otherPhone: 'Telefoonnummer',
  otherRelation: 'Relatie t.o.v. het kind',
  otherStreet: 'Straat',
  otherZip: 'Postcode',
  school: 'School',
};

const contactFieldnameMap: TStringObject = {
  audience: 'Hoedanigheid',
  email: 'Emailadres',
  firstName: 'Voornaam',
  lastName: 'Achternaam',
  message: 'Bericht',
};

//HELPERS

const getTransformationData = (type: TEmailType) => {
  if (type === 'contact') return { fieldNameMap: contactFieldnameMap, sections: contactSections };
  if (type === 'offer') return { fieldNameMap: offerFieldNameMap, sections: offerSections };
};

const getHeader = (title: string, body: TStringObject, includedFields: string[]) => {
  if (includedFields.some(key => !!body[key])) return `<h2><b>${title}</b></h2><br/>`;
  return '';
};

const getField = (fieldNameMap: TStringObject, key: string, body: TStringObject) => {
  if (body[key]) {
    return `<p><b>${fieldNameMap[key]}: </b>${body[key]}</p><br/>`;
  }
  return '';
};

//MAIN FUNCTIONS

export const transformToEmail = (body: TStringObject, type: TEmailType) => {
  const { sections, fieldNameMap } = getTransformationData(type);
  let html = '';

  sections.forEach(({ title, includedFields }) => {
    html += getHeader(title, body, includedFields);
    includedFields.forEach(field => (html += getField(fieldNameMap, field, body)));
  });

  return html;
};

export const getClientEmail = (body: TStringObject) => {
  if (!body) return undefined;

  const { email, caregiverEmail, otherEmail, motherEmail, fatherEmail, family } = body || {};

  if (email) return email;
  if (caregiverEmail) return caregiverEmail;
  if (family === 'other') return otherEmail;
  if (family === 'mother' || family === 'motherAndFather') return motherEmail;
  if (family === 'father') return fatherEmail;
};
