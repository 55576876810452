import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import childrenImage from '../_assets/images/childrenFaq.jpg';
import parentsImage from '../_assets/images/parentsFaq.jpg';
import { Markdown, PageHeader, Spinner } from '../_shared';
import InfoBox from '../_shared/infobox/InfoBox';

import { useGetFaq } from './_queries';
import './faq.scss';

type TProps = {
  audience: 'Hulpverleners' | 'Ouders' | 'Kinderen';
};

const Faq: FC<TProps> = ({ audience }) => {
  const { data, isLoading } = useGetFaq(audience);
  const { t } = useTranslation();

  const getImage = () => {
    switch (audience) {
      case 'Hulpverleners':
      case 'Ouders':
        return parentsImage;
      case 'Kinderen':
        return childrenImage;
    }
  };

  const getTitle = () => {
    switch (audience) {
      case 'Hulpverleners':
        return t('HOME.CONTENT.CAREGIVERS.TITLE');
      case 'Ouders':
        return t('HOME.CONTENT.PARENTS.TITLE');
      case 'Kinderen':
        return t('HOME.CONTENT.CHILDREN.TITLE');
    }
  };

  return (
    <>
      <PageHeader href="/" imageUrl={getImage()} title={getTitle()} />

      <section className="faq__detail__description">
        {isLoading ? (
          <Spinner size="large" />
        ) : (
          data?.map(({ question, response }, index) => (
            <InfoBox key={index} title={question}>
              <Markdown value={response.json} />
            </InfoBox>
          ))
        )}
      </section>
    </>
  );
};

export default Faq;
