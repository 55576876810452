import React, { FC, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import image from '../../_assets/images/history.jpg';
import { PageHeader, Spinner } from '../../_shared';
import { useGetHistory } from '../_queries';

import Timeline from './components/Timeline';

import './historydkv.scss';

const HistoryDkv: FC = () => {
  const { data, isLoading } = useGetHistory();
  const [activeItem, setActiveItem] = useState<string>('');
  const { t } = useTranslation();

  const handleActive = (title: string) => {
    setActiveItem(prevState => (prevState === title ? '' : title));
  };

  useEffect(() => {
    setActiveItem(data?.[0].title);
  }, []);

  return (
    <section className="historydkv">
      <PageHeader href="/overons" imageUrl={image} title={t('HISTORY')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <ul>
          {data?.map(({ title, description }) => (
            <li key={title}>
              <Timeline
                description={description}
                isVisible={activeItem === title}
                onClick={() => handleActive(title)}
                title={title}
              />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};

export default HistoryDkv;
