import { z } from 'zod';

const errorMessageRequired = z.string().min(1, { message: 'FORMS.OFFER.ERRORS.REQUIRED' });

export const stepOneSchema = z.object({
  message: errorMessageRequired,
});

export const stepTwoSchema = z.object({
  audience: errorMessageRequired,
});

export const stepThreeSchema = z.object({
  email: z.string().email().min(1, { message: 'FORMS.OFFER.ERRORS.REQUIRED' }),
  firstName: errorMessageRequired,
  lastName: errorMessageRequired,
});

export type TContactFormStepOneSchema = z.infer<typeof stepOneSchema>;
export type TContactFormStepTwoSchema = z.infer<typeof stepTwoSchema>;
export type TContactFormStepThreeSchema = z.infer<typeof stepThreeSchema>;
