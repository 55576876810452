import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import Icon from '../../../_shared/icon/Icon';
import './homesectioncontent.scss';

const HomeSectionContent: FC = () => {
  const { t } = useTranslation();

  return (
    <section className="content">
      <div className="content_subdivision">
        <Icon name="SvgChildWithBalloon" size={25} />
        <h2>{t('HOME.CONTENT.CHILDREN.TITLE')}</h2>
        <span className="content_subdivision_description">{t('HOME.CONTENT.CHILDREN.DESCRIPTION')}</span>
        <NavLink to="/faq/kinderen">
          {t('HOME.CONTENT.CHILDREN.LINK')}
          <Icon className="link" name="SvgChevronRight" size={2.5} />
        </NavLink>
      </div>
      <div className="content_subdivision">
        <Icon name="SvgParent" size={25} />
        <h2>{t('HOME.CONTENT.PARENTS.TITLE')}</h2>
        <span className="content_subdivision_description">{t('HOME.CONTENT.PARENTS.DESCRIPTION')}</span>
        <NavLink to="/faq/ouders">
          {t('HOME.CONTENT.PARENTS.LINK')}
          <Icon className="link" name="SvgChevronRight" size={2.5} />
        </NavLink>
      </div>
      <div className="content_subdivision">
        <Icon name="SvgCaregiver" size={25} />
        <h2>{t('HOME.CONTENT.CAREGIVERS.TITLE')}</h2>
        <span className="content_subdivision_description">{t('HOME.CONTENT.CAREGIVERS.DESCRIPTION')}</span>
        <NavLink to="/faq/hulpverleners">
          {t('HOME.CONTENT.CAREGIVERS.LINK')}
          <Icon className="link" name="SvgChevronRight" size={2.5} />
        </NavLink>
      </div>
    </section>
  );
};

export default HomeSectionContent;
