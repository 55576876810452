import { useState } from 'react';

import { LocalStorage } from '../_utils';
import { TContactSteps } from '../contact/contactForm/_models/contactForm';
import { TOfferSteps } from '../offers/offerForm/_models/offerForm';
import { TOfferFormSchema } from '../offers/offerForm/_schemas/offerForm.schema';

export const useStoredForm = <T>(form: Partial<T>, formName: string, currentStep: TOfferSteps | TContactSteps) => {
  const initialStoredForm = LocalStorage.getItem(formName);
  const [storedForm, setStoredForm] = useState(initialStoredForm || {});

  const setForm = (newForm: Partial<TOfferFormSchema> & { currentStep?: string } = form) => {
    if (JSON.stringify(newForm) !== JSON.stringify(storedForm)) {
      try {
        setStoredForm(newForm);
        LocalStorage.setItem(formName, newForm);
      } catch (error) {
        console.error(`Failed to set value in local storage: ${error}`);
      }
    }
  };

  const updateForm = () => setForm({ ...storedForm, ...form, currentStep });

  const clearForm = () => LocalStorage.removeItem(formName);

  const filterForm = () => {
    const fieldsToRemove = Object.keys(form);
    const filteredForm = { ...storedForm };
    fieldsToRemove.forEach(field => delete filteredForm[field]);
    setForm(filteredForm);
  };

  return { clearForm, filterForm, setForm, storedForm, updateForm };
};
