import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { Icon } from '../';
import { getImage } from '../../_utils/imageHelpers';
import './card.scss';

type TProps = {
  href: string;
  imageUrl: string;
  label?: string;
  title: string;
};

const Card: FC<TProps> = ({ imageUrl, label, title, href }) => {
  return (
    <NavLink to={href}>
      <div className="card" style={{ backgroundImage: `url(${getImage(imageUrl, 400, 400)})` }}>
        {label && <span>{label}</span>}
        <div className="card__link">
          {title}
          <Icon name="SvgChevronRight" size={4} />
        </div>
      </div>
    </NavLink>
  );
};

export default Card;
