import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import ImageHeaderJobs from '../../_assets/images/vacatures.jpg';
import { Card, PageHeader, Spinner } from '../../_shared';
import { useGetJobs } from '../_queries';

import './overview.scss';

export const Overview: FC = () => {
  const { data, isLoading } = useGetJobs();
  const { t } = useTranslation();

  return (
    <section className="overview">
      <PageHeader href="/" imageUrl={ImageHeaderJobs} title={t('SHARED.NAVIGATION.VACATURES')} />
      {isLoading ? (
        <Spinner />
      ) : data?.length > 0 ? (
        <ul>
          {data?.map(({ id, picture, title }) => (
            <li key={id}>
              <Card href={id} imageUrl={picture} title={title} />
            </li>
          ))}
        </ul>
      ) : (
        <p>{t('VACATURES.EMPTY')}</p>
      )}
    </section>
  );
};
