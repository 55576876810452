import React, { FC, ReactNode } from 'react';

import classnames from 'classnames';

import { Icon } from '../';
import { useToggle } from '../../_hooks';
import './infobox.scss';

type TProps = {
  children: ReactNode;
  date?: string[];
  title: string;
};

const InfoBox: FC<TProps> = ({ title, date, children }) => {
  const [isVisible, setIsVisible] = useToggle(false);

  return (
    <div className="infobox">
      <button className="infobox__heading" onClick={setIsVisible}>
        <div className="infobox__heading__date">
          {date && (
            <div className="infobox__heading__date__split">
              <h4>{date[0]}</h4>
              <span>{date[1]}</span>
            </div>
          )}
          <h4>{title}</h4>
        </div>
        <Icon className={classnames('infobox__info-visible', { 'infobox__info-not-visible': !isVisible })} name="SvgDropdown" />
      </button>
      {isVisible && children}
    </div>
  );
};

export default InfoBox;
