import { gql } from '@apollo/client';

export const GetWeeklyActivitySchema = gql`
  query activityWeeklyCollection($locale: String!) {
    activityWeeklyCollection(locale: $locale) {
      items {
        title
        description
        endDate
        startDate
        exceptionDates
        duration
        audience
        location
        mail
        phone
      }
    }
  }
`;
