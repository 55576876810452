import React from 'react';

import { useTranslation } from 'react-i18next';

import headerImage from '../../_assets/images/contact.jpg';
import './contactOverview.scss';
import { Card, PageHeader } from '../../_shared';
import { contactOptions } from '../_data/contactData';

export const ContactOverview = () => {
  const { t } = useTranslation();

  return (
    <section className="overview">
      <PageHeader href="/" imageUrl={headerImage} title={t('SHARED.NAVIGATION.CONTACT')} />
      <ul>
        {contactOptions?.map(({ id, picture, label, title }) => (
          <li key={id}>
            <Card href={id} imageUrl={picture} label={t(label as any)} title={t(title as any)} />
          </li>
        ))}
      </ul>
    </section>
  );
};
