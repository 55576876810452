export const contactFormStepOneDefaultValues = {
  message: '',
};
export const contactFormStepTwoDefaultValues = {
  audience: 'parent',
};
export const contactFormStepThreeDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
};
