import { UseFormSetValue } from 'react-hook-form';
import { Location, NavigateFunction } from 'react-router-dom';
import { ZodType } from 'zod';

import { TResponsibleAdult } from '../offers/offerForm/_models/offerForm';
import {
  TOfferFormSchema,
  TOfferFormStepOneSchema,
  TOfferFormStepThreeSchema,
} from '../offers/offerForm/_schemas/offerForm.schema';

export const transformUrlParam = (val: string): string => {
  let returnValue = val.toLowerCase();
  if (returnValue.includes(' ')) {
    returnValue = returnValue.split(' ')[1];
  }

  return returnValue;
};

export const handleReplaceLastUrlValue = (navigate: NavigateFunction, location: Location, newValue: string) => {
  const pathParts = location.pathname.split('/');
  pathParts[pathParts.length - 1] = newValue;
  const newPath = pathParts.join('/');

  navigate(newPath);
};

export const isAccessToStepAllowed = <T>(schema: ZodType<T, any, any>, form: Partial<TOfferFormSchema>) => {
  return schema.safeParse(form).success;
};

export const clearCaregiverFields = (setValue: UseFormSetValue<TOfferFormStepOneSchema>) => {
  setValue('caregiverFirstName', '');
  setValue('caregiverLastName', '');
  setValue('caregiverDepartment', '');
  setValue('caregiverEmail', '');
  setValue('caregiverPhone', '');
  setValue('caregiverPhone', '');
  setValue('isDiscussed', '');
};

export const clearFamilyFields = (
  setValue: UseFormSetValue<TOfferFormStepThreeSchema>,
  responsibleAdult: Exclude<TResponsibleAdult, 'motherAndFather'>,
) => {
  setValue(`${responsibleAdult}FirstName`, '');
  setValue(`${responsibleAdult}LastName`, '');
  setValue(`${responsibleAdult}Bus`, '');
  setValue(`${responsibleAdult}Email`, '');
  setValue(`${responsibleAdult}Language`, '');
  setValue(`${responsibleAdult}Street`, '');
  setValue(`${responsibleAdult}Number`, '');
  setValue(`${responsibleAdult}Zip`, '');
  setValue(`${responsibleAdult}Phone`, '');
  if (responsibleAdult === 'other') setValue('otherRelation', '');
  if (responsibleAdult === 'father') setValue('isMotherInvolved', 'no');
  if (responsibleAdult === 'mother') setValue('isFatherInvolved', 'no');
};
