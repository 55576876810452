import { gql } from '@apollo/client';

export const GetJobsCollectionSchema = gql`
  query jobCollection($locale: String!) {
    jobCollection(locale: $locale) {
      items {
        sys {
          id
        }
        title
        picture {
          url
        }
      }
    }
  }
`;
