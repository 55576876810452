import React from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../../_shared/input/checkbox/CheckboxGroup';
import { TResponsibleAdult } from '../../_models/offerForm';
import { TOfferFormStepThreeSchema } from '../../_schemas/offerForm.schema';

type Props = {
  errors: FieldErrors<TOfferFormStepThreeSchema>;
  register: UseFormRegister<TOfferFormStepThreeSchema>;
  responsibleAdult: TResponsibleAdult;
};

export const IsInvolvedQuery = ({ responsibleAdult, register, errors }: Props) => {
  if (responsibleAdult === 'motherAndFather' || responsibleAdult === 'other') return null;

  const { t } = useTranslation();

  const getInvolvedText = (responsibleAdult: TResponsibleAdult) => {
    if (responsibleAdult === 'mother') return t('FORMS.OFFER.FAMILY_INFO.DETAILS.TITLE_ISFATHERINVOLVED');
    if (responsibleAdult === 'father') return t('FORMS.OFFER.FAMILY_INFO.DETAILS.TITLE_ISMOTHERINVOLVED');
  };

  const oppositeAdult = responsibleAdult === 'mother' ? 'Father' : 'Mother';

  return (
    <CheckboxGroup
      error={errors[`is${oppositeAdult}Involved`]?.message}
      label={getInvolvedText(responsibleAdult)}
      name={`is${oppositeAdult}Involved`}
    >
      <Checkbox
        type="radio"
        {...register(`is${oppositeAdult}Involved`, { required: true })}
        label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.YES')}
        name={`is${oppositeAdult}Involved`}
        value="yes"
      />
      <Checkbox
        type="radio"
        value="no"
        {...register(`is${oppositeAdult}Involved`, { required: true })}
        label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.NO')}
        name={`is${oppositeAdult}Involved`}
      />
    </CheckboxGroup>
  );
};
