import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStoredForm } from '../../../_hooks/useStoredForm';
import { FormButtons } from '../../../_shared/form/FormButtons';
import Checkbox from '../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../_shared/input/checkbox/CheckboxGroup';
import { LocalStorage } from '../../../_utils';
import { isAccessToStepAllowed } from '../../../_utils/offerHelpers';
import { contactFormStepTwoDefaultValues } from '../_data/contactFormData';
import { TContactFormStepTwoSchema, stepOneSchema, stepTwoSchema } from '../_schemas/contactForm.schema';

const getInitialValues = () => {
  return {
    ...contactFormStepTwoDefaultValues,
    ...LocalStorage.getItem('contactForm'),
  };
};

export const ContactStepTwo = () => {
  const initialValues = getInitialValues();
  useEffect(() => {
    if (!isAccessToStepAllowed(stepOneSchema, initialValues)) {
      navigate('/contact/formulier/bericht', { replace: true });
    }
  }, []);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TContactFormStepTwoSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(stepTwoSchema),
  });
  const currentFormValues = watch();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateForm } = useStoredForm<TContactFormStepTwoSchema>(currentFormValues, 'contactForm', 'hoedanigheid');

  const handlePrevious = () => {
    navigate('/contact/formulier/bericht', { replace: true });
  };

  const handleNext: SubmitHandler<TContactFormStepTwoSchema> = () => {
    navigate({ pathname: '/contact/formulier/info' });
  };

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <CheckboxGroup error={errors.audience?.message} label={t('FORMS.CONTACT.STEPTWO.DETAILS.TITLE')} name="audience">
        <Checkbox
          type="radio"
          {...register('audience', { required: true })}
          label={t('FORMS.CONTACT.STEPTWO.DETAILS.RADIOBUTTONS.ADULT')}
          name="audience"
          value="parent"
        />
        <Checkbox
          type="radio"
          value="caregiver"
          {...register('audience', { required: true })}
          label={t('FORMS.CONTACT.STEPTWO.DETAILS.RADIOBUTTONS.CAREGIVER')}
          name="audience"
        />
        <Checkbox
          type="radio"
          value="child"
          {...register('audience', { required: true })}
          label={t('FORMS.CONTACT.STEPTWO.DETAILS.RADIOBUTTONS.CHILD')}
          name="audience"
        />
        <Checkbox
          type="radio"
          value="other"
          {...register('audience', { required: true })}
          label={t('FORMS.CONTACT.STEPTWO.DETAILS.RADIOBUTTONS.OTHER')}
          name="audience"
        />
      </CheckboxGroup>

      <FormButtons handlePrevious={handlePrevious} />
    </form>
  );
};
