import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TAboutOverview } from '../_models';
import { serializeContentfulAbout } from '../_serializers';

import { GetAboutCollectionSchema } from './_schemas';

export function useGetAbout() {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TAboutOverview>(GetAboutCollectionSchema, {
    variables: { locale: i18n.language },
  });
  const serialized = data?.aboutCollection?.items.map(about => serializeContentfulAbout(about));

  return { data: serialized, isLoading: loading };
}
