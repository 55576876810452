import JobsDefaultImage from '../_assets/images/jobsDefaultImage.jpg';

export function getImage(url: string, width?: number, height?: number): string {
  if (!url) return JobsDefaultImage;
  let res = `${url}`;
  if (width || height) {
    res += '?';
    res += width ? `w=${width}` : '';
    res += height ? `&h=${height}` : '';
    res += '&fm=jpg&fl=progressive';
  }

  return res;
}
