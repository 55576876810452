import i18n, { use } from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import HttpBackend from 'i18next-http-backend';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/order
import { languages } from '.';
import en from './locales/en.json';
import nl_BE from './locales/nl-BE.json';

use(ChainedBackend)
  .use(initReactI18next)
  .init({
    backend: {
      backendOptions: [
        {
          customHeaders: {
            accept: 'application/json',
            authorization: `Bearer ${process.env.REACT_APP_TRANSLATIONS_API_KEY}`,
          },
          loadPath: `${process.env.REACT_APP_TRANSLATIONS_API_HOST}{{lng}}`,
          parse: (data: string) => JSON.parse(data).translations,
        },
      ],
      backends: [HttpBackend, resourcesToBackend({ en: { translation: en }, 'nl-BE': { translation: nl_BE } })],
    },
    lng: languages.Dutch,
    supportedLngs: [languages.Dutch, languages.English],
    // This causes that there is no call triggered when other language is set, like cimode
  });

export default i18n;
