import { TContentfulJob, TJob } from '../_models';

export function serializeContentfulJob(job: TContentfulJob): TJob {
  if (!job) return;

  return {
    description: job.description?.json,
    filePath: job.file?.url,
    id: job.sys.id,
    picture: job.picture?.url,
    title: job.title,
  };
}
