import React from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { reverseObject } from '../../_utils/objectHelpers';
import { handleReplaceLastUrlValue } from '../../_utils/offerHelpers';
import { TFormStep } from '../../offers/offerForm/_data/offerFormSteps';
import Icon from '../icon/Icon';

type Props = {
  currentStep: number;
  stepToNumberMap: Record<string, number>;
  steps: TFormStep[];
};

export const StepIndicator = ({ currentStep, steps, stepToNumberMap }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const stepNumberToNameMap = reverseObject(stepToNumberMap);
  const isConfirmationStep = currentStep === steps.length;

  const renderCircleContent = (index: number) => {
    if (currentStep > index || isConfirmationStep) return <Icon name="SvgCheck" />;

    return index + 1;
  };

  const onClickStep = clickedStep => {
    if (currentStep <= clickedStep || currentStep + 1 === steps.length) return;

    handleReplaceLastUrlValue(navigate, location, stepNumberToNameMap[clickedStep]);
  };

  return (
    <ul>
      {steps.slice(0, steps.length - 1).map(({ title }, index) => (
        <li aria-label={`step-${index + 1}`} key={title}>
          <button className={classnames(currentStep >= index && 'step__active')} onClick={() => onClickStep(index)}>
            {renderCircleContent(index)}
          </button>
          <span className={classnames(currentStep >= index && 'step__active__text', 'step_indicator_label')}>
            {isConfirmationStep ? t(steps[currentStep - 1].title as any) : t(title as any)}
          </span>
        </li>
      ))}
    </ul>
  );
};
