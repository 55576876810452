import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStoredForm } from '../../../_hooks/useStoredForm';
import { InputField } from '../../../_shared';
import { FormButtons } from '../../../_shared/form/FormButtons';
import Checkbox from '../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../_shared/input/checkbox/CheckboxGroup';
import { LocalStorage } from '../../../_utils';
import { isAccessToStepAllowed } from '../../../_utils/offerHelpers';
import { offerFormStepTwoDefaultValues } from '../_data/offerFormData';
import {
  TOfferFormStepTwoSchema,
  offerFormStepOneSchema,
  offerFormStepTwoBaseSchema,
  offerFormStepTwoSchema,
} from '../_schemas/offerForm.schema';
import { getFormName, isHomeworkGuidanceForm } from '../_utils/offerFormUtils';

type Props = {
  formTitle: string;
  offerId: string;
};
const getInitialValues = (formTitle: string) => {
  return {
    ...offerFormStepTwoDefaultValues,
    ...LocalStorage.getItem(getFormName(formTitle)),
  };
};

export const OfferStepTwo = ({ offerId, formTitle }: Props) => {
  const shouldRenderSchoolFields = isHomeworkGuidanceForm(offerId);
  const stepTwoSchema = shouldRenderSchoolFields ? offerFormStepTwoSchema : offerFormStepTwoBaseSchema;
  const initialValues = getInitialValues(formTitle);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAccessToStepAllowed(offerFormStepOneSchema, initialValues)) {
      navigate(`/hulpaanbod/${offerId}/aanvraag/jezelf`, { replace: true });
    }
  }, []);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TOfferFormStepTwoSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(stepTwoSchema),
  });

  const { t } = useTranslation();
  const currentFormValues = watch();
  const { updateForm } = useStoredForm<TOfferFormStepTwoSchema>(currentFormValues, getFormName(formTitle), 'kind');

  const handlePrevious = () => {
    navigate(`/hulpaanbod/${offerId}/aanvraag/jezelf`, { replace: true });
  };

  const handleNext: SubmitHandler<TOfferFormStepTwoSchema> = () => {
    navigate({ pathname: `/hulpaanbod/${offerId}/aanvraag/gezin` });
  };

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <InputField
        {...register('childFirstName', { required: true })}
        error={errors.childFirstName?.message}
        label={t('FORMS.CONTACT.INFO.FIRSTNAME')}
        name="childFirstName"
      />
      <InputField
        {...register('childLastName', { required: true })}
        error={errors.childLastName?.message}
        label={t('FORMS.CONTACT.INFO.LASTNAME')}
        name="childLastName"
      />
      <InputField
        {...register('childBirthdate', { required: true })}
        error={errors.childBirthdate?.message}
        label={t('FORMS.OFFER.CHILD_INFO.DETAILS.CHILD_BIRTHDATE')}
        name="childBirthdate"
        type="date"
      />
      <CheckboxGroup error={errors.gender?.message} label={t('FORMS.OFFER.CHILD_INFO.DETAILS.TITLE_GENDER')} name="isDiscussed">
        <Checkbox
          type="radio"
          {...register('gender', { required: true })}
          label={t('FORMS.OFFER.CHILD_INFO.DETAILS.RADIOBUTTONS_GENDER.M')}
          name="gender"
          value="m"
        />
        <Checkbox
          type="radio"
          value="w"
          {...register('gender', { required: true })}
          label={t('FORMS.OFFER.CHILD_INFO.DETAILS.RADIOBUTTONS_GENDER.W')}
          name="gender"
        />
      </CheckboxGroup>
      {shouldRenderSchoolFields && (
        <>
          <InputField
            {...register('school', { required: true })}
            error={errors.school?.message}
            label={t('FORMS.OFFER.CHILD_INFO.DETAILS.SCHOOL')}
            name="school"
          />
          <InputField
            {...register('grade', { required: true })}
            error={errors.grade?.message}
            label={t('FORMS.OFFER.CHILD_INFO.DETAILS.GRADE')}
            name="grade"
          />
        </>
      )}
      <FormButtons handlePrevious={handlePrevious} />
    </form>
  );
};
