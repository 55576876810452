import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { LocalStorage } from '../../_utils';
import Button from '../button/Button';
import './contact.scss';

const Contact: FC = () => {
  const { t } = useTranslation();
  const storedForm = LocalStorage.getItem('contactForm');

  const path = storedForm?.currentStep ? storedForm?.currentStep : 'bericht';

  return (
    <section className="contact">
      <h2>{t('SHARED.CONTACT.TITLE')}</h2>
      <p>{t('SHARED.CONTACT.DESCRIPTION')}</p>
      <Button href={`/contact/formulier/${path}`} theme="primary">
        {t('SHARED.CONTACT.TITLE')}
      </Button>
    </section>
  );
};
export default Contact;
