import { gql } from '@apollo/client';

export const GetAboutDetailSchema = gql`
  query about($id: String!, $locale: String!) {
    about(id: $id, locale: $locale) {
      sys {
        id
      }
      title
      description {
        json
      }
      picture {
        url
      }
    }
  }
`;
