export const offerFormStepOneDefaultValues = {
  audience: 'parent' as const,
  caregiverDepartment: '',
  caregiverEmail: '',
  caregiverFirstName: '',
  caregiverLastName: '',
  caregiverPhone: '',
  isDiscussed: '',
};

export const offerFormStepTwoDefaultValues = {
  childBirthdate: new Date(),
  childFirstName: '',
  childLastName: '',
  gender: 'm',
  grade: '',
  school: '',
};

export const offerFormStepThreeDefaultValues = {
  family: 'mother' as const,
  fatherBus: '',
  fatherEmail: '',
  fatherFirstName: '',
  fatherLanguage: '',
  fatherLastName: '',
  fatherNumber: '',
  fatherPhone: '',
  fatherStreet: '',
  fatherZip: '',
  isFatherInvolved: 'no',
  isMotherInvolved: 'no',
  isSameAddress: '',
  motherBus: '',
  motherEmail: '',
  motherFirstName: '',
  motherLanguage: '',
  motherLastName: '',
  motherNumber: '',
  motherPhone: '',
  motherStreet: '',
  motherZip: '',
  otherBus: '',
  otherEmail: '',
  otherFirstName: '',
  otherLanguage: '',
  otherLastName: '',
  otherNumber: '',
  otherPhone: '',
  otherRelation: '',
  otherStreet: '',
  otherZip: '',
};

export const offerFormStepFourDefaultValues = {
  answer1: '',
  answer2: '',
  answer3: '',
  answer4: '',
  answer5: '',
  answer6: '',
  answer7: '',
  answer8: '',
};

export const offerFormDefaultValues = {
  ...offerFormStepOneDefaultValues,
  ...offerFormStepTwoDefaultValues,
  ...offerFormStepThreeDefaultValues,
  ...offerFormStepFourDefaultValues,
};
