import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TJobDetail } from '../_models';
import { serializeContentfulJob } from '../_serializers';

import { GetJobByIdSchema } from './_schemas';

export const useGetJob = () => {
  const { i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useQuery<TJobDetail>(GetJobByIdSchema, {
    variables: { id, locale: i18n.language },
  });

  const serialized = serializeContentfulJob(data?.job);

  return { data: serialized, isLoading: loading };
};
