import React from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputField } from '../../../../_shared';
import { TResponsibleAdult } from '../../_models/offerForm';
import { TOfferFormStepThreeSchema } from '../../_schemas/offerForm.schema';

type Props = {
  errors: FieldErrors<TOfferFormStepThreeSchema>;
  register: UseFormRegister<TOfferFormStepThreeSchema>;
  responsibleAdult: Exclude<TResponsibleAdult, 'motherAndFather'>;
};

export const AddressFields = ({ responsibleAdult, register, errors }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <InputField
        {...register(`${responsibleAdult}Street`, { required: true })}
        error={errors[`${responsibleAdult}Street`]?.message}
        label={t('FORMS.OFFER.SHARED.STREET')}
        name={`${responsibleAdult}Street`}
      />
      <div className="input-fields-bus-phone-container">
        <InputField
          wrapperClassName="input-field-phone"
          {...register(`${responsibleAdult}Number`, { required: true })}
          error={errors[`${responsibleAdult}Number`]?.message}
          label={t('FORMS.OFFER.SHARED.NUMBER')}
          name={`${responsibleAdult}Number`}
        />
        <InputField
          wrapperClassName="input-field-bus"
          {...register(`${responsibleAdult}Bus`, { required: true })}
          error={errors[`${responsibleAdult}Bus`]?.message}
          label={t('FORMS.OFFER.SHARED.BUS')}
          name={`${responsibleAdult}Bus`}
        />
      </div>
      <InputField
        {...register(`${responsibleAdult}Zip`, { required: true })}
        error={errors[`${responsibleAdult}Zip`]?.message}
        label={t('FORMS.OFFER.SHARED.ZIP')}
        name={`${responsibleAdult}Zip`}
      />
    </>
  );
};
