import { useState } from 'react';

import emailjs from '@emailjs/browser';

import { TEmailType, getClientEmail, transformToEmail } from '../_utils/emailHelpers';
import { Config } from '../config';

const templates: Record<TEmailType, string> = {
  contact: Config.emailjs.contactTemplateId,
  offer: Config.emailjs.offerTemplateId,
};

export const useSendEmail = <T extends TEmailType>(type: T) => {
  const [error, setError] = useState<Error>();

  const sendEmail = async (body, formTitle?: string) => {
    try {
      await emailjs.send(Config.emailjs.serviceId, templates[type], {
        clientEmail: getClientEmail(body),
        formTitle,
        html: transformToEmail(body, type),
      });
      return true;
    } catch (e) {
      setError(e as Error);
      return false;
    }
  };

  return {
    error,
    sendEmail,
  };
};
