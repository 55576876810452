import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon, Markdown, PageHeader, Spinner } from '../../_shared';
import { useGetJob } from '../_queries';
import './detail.scss';

export const Detail: FC = () => {
  const { data, isLoading } = useGetJob();
  const { t } = useTranslation();

  if (isLoading) return <Spinner overlay />;
  return (
    <>
      <PageHeader href="/vacatures" imageUrl={data?.picture} title={data?.title} />
      <section className="detail__description">
        <h4>{data?.title}</h4>
        <Markdown className="detail__description_body" value={data?.description} />
        <a className="detail__description__download" href={data?.filePath} target="blank">
          {t('VACATURES.DOWNLOAD')} <Icon name="SvgDownload" />
        </a>
        <Button className="detail__description_solicitation" href="/contact">
          {t('VACATURES.BUTTON')}
          <Icon name="SvgChevronRight" size={2} />
        </Button>
      </section>
    </>
  );
};
