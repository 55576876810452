import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import header from '../../_assets/images/dekleinevoscontact.jpg';
import { Icon, PageHeader } from '../../_shared';
import { Config } from '../../config';
import './detailKakelbont.scss';

export const DetailKakelbont: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader href="/contact" imageUrl={header} title={t('CONTACT.KAKELBONT.TITLE')} />

      <section className="contact__detail__description">
        <h4>{t('CONTACT.TITLES.INFO')}</h4>
        <ul>
          <li>
            <a href={`tel:${t('CONTACT.KAKELBONT.INFO.TEL')}`}>{t('CONTACT.KAKELBONT.INFO.TEL')}</a>
          </li>
          <li>
            <a href={`mailto:${t('CONTACT.KAKELBONT.INFO.MAIL')}`}>{t('CONTACT.KAKELBONT.INFO.MAIL')}</a>
          </li>
        </ul>
        <ul>
          <li>{t('CONTACT.KAKELBONT.INFO.ADDRESS.TITLE')}</li>
          <li>{t('CONTACT.KAKELBONT.INFO.ADDRESS.NAME')}</li>
          <li>{t('CONTACT.KAKELBONT.INFO.ADDRESS.STREET')}</li>
          <li>{t('CONTACT.KAKELBONT.INFO.ADDRESS.CITY')}</li>
        </ul>
        <h4>{t('CONTACT.TITLES.REACHABILITY')}</h4>
        <p>{t('CONTACT.KAKELBONT.INFO.REACHABILITY.DESCRIPTION')}</p>
        <ul>
          <li>{t('CONTACT.KAKELBONT.INFO.REACHABILITY.TRAM')}</li>
          <li>{t('CONTACT.KAKELBONT.INFO.REACHABILITY.BUS_1')}</li>
          <li>{t('CONTACT.KAKELBONT.INFO.REACHABILITY.BUS_2')}</li>
        </ul>
        <a href={Config.deKleineVosMaps} rel="noreferrer" target="_blank">
          {t('SHARED.BUTTONS.OPENING_IN_MAPS')} <Icon name="SvgChevronRight" size={3} />
        </a>
      </section>
    </>
  );
};
