import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSendEmail } from '../../../_hooks/useSendEmail';
import { useStoredForm } from '../../../_hooks/useStoredForm';
import { InputField } from '../../../_shared';
import { FormButtons } from '../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../_utils';
import { isAccessToStepAllowed } from '../../../_utils/offerHelpers';
import { contactFormStepThreeDefaultValues } from '../_data/contactFormData';
import { TContactFormStepThreeSchema, stepThreeSchema, stepTwoSchema } from '../_schemas/contactForm.schema';

const getInitialValues = () => {
  return {
    ...contactFormStepThreeDefaultValues,
    ...LocalStorage.getItem('contactForm'),
  };
};

export const ContactStepThree = () => {
  const initialValues = getInitialValues();
  useEffect(() => {
    if (!isAccessToStepAllowed(stepTwoSchema, initialValues)) {
      navigate('/contact/formulier/hoedanigheid', { replace: true });
    }
  }, []);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TContactFormStepThreeSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(stepThreeSchema),
  });
  const currentFormValues = watch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { clearForm, updateForm, storedForm } = useStoredForm<TContactFormStepThreeSchema>(
    currentFormValues,
    'contactForm',
    'info',
  );
  const { sendEmail } = useSendEmail('contact');

  const handlePrevious = () => {
    navigate('/contact/formulier/hoedanigheid', { replace: true });
  };

  const handleNext: SubmitHandler<TContactFormStepThreeSchema> = async () => {
    const isSuccess = await sendEmail(storedForm);
    if (isSuccess) clearForm();
    navigate({ pathname: `/contact/formulier/confirmation/${isSuccess ? '' : 'error'}` });
  };

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <InputField
        {...register('firstName', { required: true })}
        error={errors.firstName?.message}
        label={t('FORMS.CONTACT.INFO.FIRSTNAME')}
        name="firstName"
      />
      <InputField
        {...register('lastName', { required: true })}
        error={errors.lastName?.message}
        label={t('FORMS.CONTACT.INFO.LASTNAME')}
        name="lastName"
      />
      <InputField
        {...register('email', { required: true })}
        error={errors.email?.message}
        label={t('FORMS.OFFER.SHARED.EMAIL')}
        name="email"
      />
      <FormButtons handlePrevious={handlePrevious} />
    </form>
  );
};
