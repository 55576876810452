import React, { ReactNode } from 'react';

import { useTranslation } from 'react-i18next';

import { TFormStep } from '../../offers/offerForm/_data/offerFormSteps';

import './formwrapper.scss';
import { StepIndicator } from './StepIndicator';

type Props = {
  children: ReactNode;
  currentStep: number;
  hasError: boolean;
  stepToNumberMap: Record<string, number>;
  steps: TFormStep[];
  title: string;
};

const FormWrapper = ({ stepToNumberMap, currentStep, hasError, children, title, steps }: Props) => {
  const { t } = useTranslation();
  if (!steps[currentStep]) return null;

  return (
    <main className="form">
      <h3>{title}</h3>
      <StepIndicator currentStep={currentStep} stepToNumberMap={stepToNumberMap} steps={steps} />
      <div className="form__wrapper">
        {hasError ? (
          <>
            <h4>{t('FORMS.CONTACT.ERROR.TITLE')}</h4>
            <p>{t('FORMS.CONTACT.ERROR.MESSAGE')}</p>
            <hr />
          </>
        ) : (
          <>
            <h4>{t(steps[currentStep]?.title as any)}</h4>
            <p>{t(steps[currentStep]?.description as any)}</p>
            <hr />
          </>
        )}
        {children}
      </div>
    </main>
  );
};

export default FormWrapper;
