import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TJobsOverview } from '../_models';
import { serializeContentfulJob } from '../_serializers';

import { GetJobsCollectionSchema } from './_schemas';

export const useGetJobs = () => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TJobsOverview>(GetJobsCollectionSchema, {
    variables: { locale: i18n.language },
  });

  const serialized = data?.jobCollection.items.map(job => serializeContentfulJob(job));

  return { data: serialized, isLoading: loading };
};
