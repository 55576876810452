import { gql } from '@apollo/client';

export const GetOfferCollectionSchema = gql`
  query offerCollection($locale: String!) {
    offerCollection(locale: $locale) {
      items {
        sys {
          id
        }
        title
        ageCategory
        picture {
          url
        }
      }
    }
  }
`;
