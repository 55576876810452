import React from 'react';

import { useTranslation } from 'react-i18next';

import headerImage from '../../_assets/images/hulpaanbod.jpg';
import { Card, PageHeader, Spinner } from '../../_shared';
import { useGetOffers } from '../_queries';

import './offersoverview.scss';

export const OffersOverview = () => {
  const { data, isLoading } = useGetOffers();
  const { t } = useTranslation();

  return (
    <section className="overview">
      <PageHeader href="/" imageUrl={headerImage} title={t('SHARED.NAVIGATION.HELP')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <ul>
          {data?.map(({ id, picture, ageCategory, title }) => (
            <li key={id}>
              <Card href={id} imageUrl={picture} label={ageCategory} title={title} />
            </li>
          ))}
        </ul>
      )}
    </section>
  );
};
