import dkvImage from '../../_assets/images/dekleinevoscontact.jpg';
import kakelbontImage from '../../_assets/images/kakelbontContact.jpg';

export const contactOptions = [
  {
    href: '/dekleinevos',
    id: 'dekleinevos',
    label: 'CONTACT.DKV.LABEL',
    picture: dkvImage,
    title: 'CONTACT.DKV.TITLE',
  },
  {
    href: '/kakelbont',
    id: 'kakelbont',
    label: 'CONTACT.KAKELBONT.LABEL',
    picture: kakelbontImage,
    title: 'CONTACT.KAKELBONT.TITLE',
  },
];
