import React, { FC, useEffect, useLayoutEffect, useState } from 'react';

import classnames from 'classnames';
import { Turn as Hamburger } from 'hamburger-react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

import useResponsive from '../../_hooks/useResponsive';
import { Icon } from '../../_shared';

import MenuLinks from './MenuLinks';

import './menu.scss';

const Menu: FC = () => {
  const [isNavTop, setIsNavTop] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(false);
  const { Responsive } = useResponsive();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useLayoutEffect(() => setOpen(false), [pathname]);

  useLayoutEffect(() => {
    const closeMenu = () => setOpen(false);
    const checkIsNavTop = () => setIsNavTop(window.scrollY < 1);

    window.addEventListener('scroll', checkIsNavTop);
    window.addEventListener('resize', closeMenu);

    return () => {
      window.removeEventListener('scroll', checkIsNavTop);
      window.removeEventListener('resize', closeMenu);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
  }, [open]);

  return (
    <>
      <nav className={classnames('nav', isNavTop && !open && !pathname.includes('formulier') && 'navbar-top')}>
        <NavLink to="/">
          <Icon name="SvgLogo" size={8} />
        </NavLink>

        {Responsive.isBiggerThan('desktop') ? (
          <div className="nav__links">
            <MenuLinks />
          </div>
        ) : (
          <div>
            <Hamburger toggle={setOpen} toggled={open} />
            {!open && <span>{t('SHARED.MENU')}</span>}
          </div>
        )}
      </nav>
      <aside className={classnames('mobile-menu', open && 'open')}>
        <MenuLinks />
      </aside>
    </>
  );
};
export default Menu;
