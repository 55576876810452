import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import workers from '../../_assets/images/bedanking.jpg';
import { PageHeader, Spinner } from '../../_shared';
import InfoBox from '../../_shared/infobox/InfoBox';
import { getImage } from '../../_utils/imageHelpers';
import { useGetEmployee } from '../_queries';

import './workers.scss';

const Workers: FC = () => {
  const { data, isLoading } = useGetEmployee();
  const { t } = useTranslation();

  return (
    <>
      <PageHeader href="/overons" imageUrl={workers} title={t('WORKERS.TITLE')} />
      <section className="detail__description">
        <h3>{t('WORKERS.SUBTITLE')}</h3>
        <p>{t('WORKERS.DESCRIPTION')}</p>
        {isLoading ? (
          <Spinner />
        ) : (
          //To make a new unique groupName array to avoid repeating the same groupName more than one time.
          [...new Set(data?.map(item => item.group))].map((groupsName: string) => (
            <InfoBox key={groupsName} title={groupsName}>
              <div className="employees">
                {data
                  ?.filter(subGroupName => subGroupName.group === groupsName)
                  .map(({ mail, name, picture, jobFunction }) => (
                    <div className="employees__single_employee" key={`${name} - ${jobFunction}`}>
                      <img alt={name} loading="lazy" src={getImage(picture)} />
                      <div>
                        <h4>{name}</h4>
                        <p>{jobFunction}</p>
                        {mail && <p>{mail}</p>}
                      </div>
                    </div>
                  ))}
              </div>
            </InfoBox>
          ))
        )}
      </section>
    </>
  );
};

export default Workers;
