import React from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputField } from '../../../../_shared';
import { TResponsibleAdult } from '../../_models/offerForm';
import { TOfferFormStepThreeSchema } from '../../_schemas/offerForm.schema';

import { AddressFields } from './AddressFields';

type Props = {
  errors: FieldErrors<TOfferFormStepThreeSchema>;
  hideAddressFields?: 'yes' | 'no';
  register: UseFormRegister<TOfferFormStepThreeSchema>;
  responsibleAdult: Exclude<TResponsibleAdult, 'motherAndFather'>;
};

export const FamilyFields = ({ responsibleAdult, register, errors, hideAddressFields = 'no' }: Props) => {
  const { t } = useTranslation();

  const getHeaderText = (responsibleAdult: TResponsibleAdult) => {
    if (responsibleAdult === 'mother') return t('FORMS.OFFER.FAMILY_INFO.DETAILS.MOTHER');
    if (responsibleAdult === 'father') return t('FORMS.OFFER.FAMILY_INFO.DETAILS.FATHER');
    if (responsibleAdult === 'other') return t('FORMS.OFFER.FAMILY_INFO.DETAILS.OTHER');
  };

  return (
    <>
      <h5>{getHeaderText(responsibleAdult)}</h5>
      <InputField
        {...register(`${responsibleAdult}FirstName`, { required: true })}
        error={errors[`${responsibleAdult}FirstName`]?.message}
        label={t('FORMS.CONTACT.INFO.FIRSTNAME')}
        name={`${responsibleAdult}FirstName`}
      />
      <InputField
        {...register(`${responsibleAdult}LastName`, { required: true })}
        error={errors[`${responsibleAdult}LastName`]?.message}
        label={t('FORMS.CONTACT.INFO.LASTNAME')}
        name={`${responsibleAdult}LastName`}
      />
      {responsibleAdult === 'other' && (
        <InputField
          {...register(`${responsibleAdult}Relation`, { required: true })}
          error={errors[`${responsibleAdult}Relation`]?.message}
          label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.OTHER_RELATION')}
          name={`${responsibleAdult}Relation`}
        />
      )}
      <InputField
        {...register(`${responsibleAdult}Email`, { required: true })}
        error={errors[`${responsibleAdult}Email`]?.message}
        label={t('FORMS.CONTACT.INFO.EMAIL')}
        name={`${responsibleAdult}Email`}
      />
      <InputField
        type="tel"
        {...register(`${responsibleAdult}Phone`, { required: true })}
        error={errors[`${responsibleAdult}Phone`]?.message}
        label={t('FORMS.OFFER.SHARED.PHONE')}
        name={`${responsibleAdult}Phone`}
      />
      <InputField
        {...register(`${responsibleAdult}Language`, { required: true })}
        error={errors[`${responsibleAdult}Language`]?.message}
        label={t('FORMS.OFFER.SHARED.LANGUAGE')}
        name={`${responsibleAdult}Language`}
      />
      {hideAddressFields === 'no' && <AddressFields errors={errors} register={register} responsibleAdult={responsibleAdult} />}
    </>
  );
};
