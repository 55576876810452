export type TFormStep = {
  description: string;
  title: string;
};

export const contactFormSteps: TFormStep[] = [
  {
    description: 'FORMS.CONTACT.MESSAGE.DESCRIPTION',
    title: 'FORMS.CONTACT.MESSAGE.TITLE',
  },
  {
    description: 'FORMS.CONTACT.STEPTWO.DESCRIPTION',
    title: 'FORMS.CONTACT.OPTIONS.TITLE',
  },
  {
    description: 'FORMS.CONTACT.INFO.DESCRIPTION',
    title: 'FORMS.CONTACT.INFO.TITLE',
  },
  {
    description: 'FORMS.OFFER.FINAL.DESCRIPTION',
    title: 'FORMS.OFFER.FINAL.TITLE',
  },
];
