import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { isInCurrentMonth } from '../../_utils';
import { TActivity, TSingleActivities } from '../_models';
import { serializeContentfulActivity } from '../_serializers';

import { GetSingleActivitySchema } from './_schemas';

/**
 * Gets all Contentful single activities from a certain month
 * @param {Date} date - Date to get month from
 * @returns {TActivity[]} singleActivities, isLoading - List of all single activities in concerned month, boolean loading
 */
export function useGetSingleActivities(date: Date) {
  const { i18n } = useTranslation();
  const dateToCompare = new Date(date);
  const { data, loading } = useQuery<TSingleActivities>(GetSingleActivitySchema, {
    variables: { locale: i18n.language },
  });

  const singleList = data?.activityOnceCollection.items.reduce<TActivity[]>((allActivities: TActivity[], activity) => {
    const serializedActivity = serializeContentfulActivity(activity);
    const serializedStartDate = new Date(serializedActivity.startDate);

    if (isInCurrentMonth(serializedStartDate, dateToCompare)) return [...allActivities, serializedActivity];
    return allActivities;
  }, []);

  // data: singleList || [] => Error: singleList is not iterable
  return { data: singleList || [], isLoading: loading };
}
