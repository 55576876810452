import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import image from '../_assets/images/about.jpg';
import { PageHeader } from '../_shared';

import './disclaimer.scss';

const Disclaimer: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageHeader href="/" imageUrl={image} title={t('SHARED.FOOTER.DISCLAIMER')} />
      <section className="disclaimer">
        <h3>Identiteit van de organisatie</h3>
        <p>De kleine Vos vzw</p>
        <p>Vosstraat 164</p>
        <p>2140 Antwerpen-Borgerhout</p>
        <p>Ondernemingsnummer: 0419.384.943</p>
        <p>Algemeen directeur: Danielle Van Echelpoel</p>
        <h3>Disclaimer</h3>
        <p>De Kleine Vos vzw streeft een maximale betrouwbaarheid na van de gegevens die voorkomen op deze website.</p>
        <p>
          De Kleine Vos vzw kan op geen enkele manier aansprakelijk worden gesteld voor mogelijke fouten of onnauwkeurigheden op
          haar website, noch voor verlies, ongemak of directe dan wel indirecte schade geleden ten gevolge van het gebruik van op
          deze website voorkomende incorrecte informatie.
        </p>
        <p>
          De informatie wordt u kosteloos verstrekt, en het gebruik van de gegevens van de website gebeurt op de eigen
          verantwoordelijkheid van de gebruiker.
        </p>
        <p>
          De eventuele links naar andere sites zijn vrijblijvend, en betekenen niet dat de inhoud van de site waarnaar verwezen
          wordt, door De Kleine Vos vzw wordt bijgetreden of is nagezien.
        </p>
        <p>
          Al hetgeen op deze website wordt weergegeven mag slechts gereproduceerd worden mits onze uitdrukkelijke, schriftelijke
          en voorafgaande toestemming. Dit betreft o.a. alle teksten, afbeeldingen, videobestanden en geluidsbestanden.
        </p>
        <p>
          Alle geschillen i.v.m. het gebruik van deze site of enig daarin vermeld gegeven, vallen onder de toepassing van het
          Belgisch recht en onder de uitsluitende bevoegdheid van de rechtbanken van Antwerpen.
        </p>
        <p>
          Door het surfen op deze website, hetzij door uw persoonsgegevens mee te delen, verklaart u zich uitdrukkelijk akkoord
          met de wijze waarop persoonsgegevens via deze website verzameld en verwerkt worden zoals aangegeven in onze
          privacyverklaring.
        </p>
      </section>
    </>
  );
};

export default Disclaimer;
