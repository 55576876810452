import { TContentfulHistory, THistory } from '../_models';

/**
 * Serializes incoming history data from Contentful.
 * @param {TContentfulHistory} history - History API data from Contentful
 * @returns {THistory} - Serialized history data
 */
export function serializeContentfulHistory(history: TContentfulHistory): THistory {
  if (!history) return;

  return {
    description: history.description?.json,
    title: history.title,
  };
}
