import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Button } from '../_shared';

import HomeSectionContent from './_components/content/HomeSectionContent';

import './home.scss';

const Home: FC = () => {
  const { t } = useTranslation();

  return (
    <div className="home">
      <header className="home_header">
        <div className="home_header_description">
          <h1>{t('HOME.HEADER.TITLE')}</h1>
          <p>{t('HOME.HEADER.INTRO')}</p>
          <Button className="home_header_description_button" href="/overons">
            {t('HOME.HEADER.BUTTON')}
          </Button>
        </div>
      </header>
      <HomeSectionContent />
    </div>
  );
};
export default Home;
