import { gql } from '@apollo/client';

export const GetMonthlyActivitySchema = gql`
  query activityMonthlyCollection($locale: String!) {
    activityMonthlyCollection(locale: $locale) {
      items {
        title
        description
        endDate
        startDate
        exceptionDates
        duration
        audience
        location
        mail
        phone
        dateOrDayOfWeek
      }
    }
  }
`;
