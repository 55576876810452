import React, { ChangeEvent, FC, Ref, forwardRef } from 'react';

import classnames from 'classnames';

import InputWrapper, { TInputWrapperProps } from '../InputWrapper';

import './checkbox.scss';

export type TCheckboxProps = TInputWrapperProps & {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: 'checkbox' | 'radio';
  value: string;
};

const Checkbox: FC<TCheckboxProps> = forwardRef(({ onChange, type, value, ...wrapperProps }, ref: Ref<HTMLInputElement>) => {
  const { disabled, name } = wrapperProps;
  return (
    <InputWrapper
      className={classnames('checkbox-wrapper', { 'is-disabled': disabled })}
      {...wrapperProps}
      isReversed
      refInput={ref}
    >
      <input name={name} onChange={onChange} ref={ref} type={type} value={value} />
    </InputWrapper>
  );
});

export default Checkbox;
