import { gql } from '@apollo/client';

export const GetHistoryCollectionSchema = gql`
  query historyCollection($locale: String!) {
    historyCollection(locale: $locale) {
      items {
        title
        description {
          json
        }
      }
    }
  }
`;
