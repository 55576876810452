import { gql } from '@apollo/client';

export const GetFaq = gql`
  query GetFaqsByAudience($audience: String!) {
    faqCollection(where: { audience: $audience }) {
      items {
        question
        response {
          json
        }
      }
    }
  }
`;
