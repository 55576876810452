import { isAfter, isBefore, isEqual } from 'date-fns';

import { TActivity } from '../calendar/_models';

export function isStartdateBeforeAndEnddateAfter(startDate: Date, endDate: Date, dateToCompare: Date): boolean {
  return isBefore(startDate, dateToCompare) && isAfter(endDate, dateToCompare);
}

export function isInCurrentMonth(startDate: Date, dateToCompare: Date): boolean {
  return isEqual(startDate.getMonth(), dateToCompare.getMonth()) && isEqual(startDate.getFullYear(), dateToCompare.getFullYear());
}

export function addActivityWhenNotOnExceptionDate(
  activity: TActivity,
  activityList: TActivity[],
  exceptionDates: string[],
): TActivity[] {
  const activityStartDate = new Date(activity.startDate);

  if (exceptionDates?.length > 0) {
    activity?.exceptionDates?.forEach(exceptionDate => {
      if (!isEqual(new Date(exceptionDate), activityStartDate)) {
        activityList.push(activity);
      }
    });

    return activityList;
  }
  activityList.push(activity);

  return activityList;
}
