import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TOffersOverview } from '../_models/offersOverview';
import { serializeContentfulOffer } from '../_serializers';

import { GetOfferCollectionSchema } from './_schemas';

export const useGetOffers = () => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TOffersOverview>(GetOfferCollectionSchema, {
    variables: { locale: i18n.language },
  });
  const serialized = data?.offerCollection?.items.map(serializeContentfulOffer);

  return { data: serialized, isLoading: loading };
};
