import React, { FC, ReactNode } from 'react';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from '../icon/Icon';

import './inputWrapper.scss';

/**
 * This component serves as a wrapper around the specific input components. It contains some common input logic:
 *  - Show input label and icon
 *  - Show error message if needed
 *  - Show whether a field is required
 */
export type TInputWrapperProps = {
  className?: string;
  disabled?: boolean;
  error?: any;
  isReversed?: boolean;
  label?: string;
  labelIcon?: string;
  name: string;
  refInput?: React.Ref<any>;
  required?: boolean;
};

const InputWrapper: FC<TInputWrapperProps & { children: ReactNode }> = ({
  children,
  className = '',
  disabled,
  label,
  labelIcon,
  name,
  required,
  isReversed = false,
  refInput,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classnames('input-wrapper', { disabled, required }, className)} ref={refInput}>
      <>
        {isReversed && children}
        {!!label && (
          <label className="input-wrapper-label" htmlFor={name}>
            {!!labelIcon && <Icon name={labelIcon} />}
            <span>{label}</span>
          </label>
        )}
        {!isReversed && children}
      </>
      {error && <div className="error">{t(error)}</div>}
    </div>
  );
};

export default InputWrapper;
