import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import FormWrapper from '../../_shared/form/FormWrapper';

import { contactFormSteps } from './_data/contactFormSteps';
import { ContactConfirmationStep } from './components/ContactConfirmationStep';
import { ContactStepOne } from './components/ContactStepOne';
import { ContactStepThree } from './components/ContactStepThree';
import { ContactStepTwo } from './components/ContactStepTwo';

export const stepToNumberMap = {
  bericht: 0,
  confirmation: 3,
  hoedanigheid: 1,
  info: 2,
};

export const ContactForm = () => {
  const { step, error } = useParams<{ error: string; step: string }>();
  const { t } = useTranslation();
  const stepNumber = stepToNumberMap[step];
  const [currentStep, setCurrentStep] = useState(stepNumber);

  useEffect(() => {
    if (stepNumber !== currentStep) setCurrentStep(stepNumber);
  }, [step]);

  const stepContent = {
    0: <ContactStepOne />,
    1: <ContactStepTwo />,
    2: <ContactStepThree />,
    3: <ContactConfirmationStep error={!!error} />,
  };

  return (
    <FormWrapper
      currentStep={currentStep}
      hasError={!!error}
      stepToNumberMap={stepToNumberMap}
      steps={contactFormSteps}
      title={t('SHARED.CONTACT.TITLE')}
    >
      {stepContent[currentStep]}
    </FormWrapper>
  );
};
