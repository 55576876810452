import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useSendEmail } from '../../../_hooks/useSendEmail';
import { FormButtons } from '../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../_utils';
import { getFormName } from '../_utils/offerFormUtils';

type Props = {
  error?: boolean;
  formTitle: string;
  offerId: string;
};

export const FormConfirmationStep = ({ error = false, formTitle, offerId }: Props) => {
  const navigate = useNavigate();
  const { sendEmail } = useSendEmail('offer');

  const specialStep = error ? 'confirmationError' : 'confirmation';
  const formName = getFormName(formTitle);
  const storedForm = LocalStorage.getItem(formName);

  const resendMail = async () => {
    const isSuccess = await sendEmail(storedForm);
    if (isSuccess) LocalStorage.removeItem(formName);
    navigate(`/hulpaanbod/${offerId}/aanvraag/confirmation/${isSuccess ? '' : 'error'}`, { replace: true });
  };

  return <FormButtons resendMail={resendMail} specialStep={specialStep} />;
};
