import { TContentfulOffer, TOffer } from '../_models';

/**
 * Serializes incoming offer data from Contentful.
 * @param {TContentfulOffer} offer - Offer API data from Contentful
 * @returns {TOffer} - Serialized offer data
 */
export function serializeContentfulOffer(offer: TContentfulOffer): TOffer {
  if (!offer) return;

  return {
    ageCategory: offer.ageCategory,
    description: offer.description?.json,
    formId: offer.formId,
    id: offer.sys.id,
    isRequestable: offer.isRequestable,
    picture: offer.picture.url,
    title: offer.title,
  };
}
