import React, { FC } from 'react';

import { Markdown, PageHeader, Spinner } from '../../_shared';
import { useGetAboutDetail } from '../_queries';
import './detail.scss';

export const Detail: FC = () => {
  const { data, isLoading } = useGetAboutDetail();

  if (isLoading) return <Spinner overlay />;

  return (
    <>
      <PageHeader href="/overons" imageUrl={data?.picture} title={data?.title} />
      <section className="detail__description">
        <Markdown value={data?.description} />
      </section>
    </>
  );
};
