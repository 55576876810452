import { useEffect, useState } from 'react';

/* Keep in sync _breakpoints.scss */
const breakpoints = {
  bigMonitor: 1640,
  desktop: 1025,
  monitor: 1280,
  tablet: 768,
};

export type BreakpointName = 'bigMonitor' | 'desktop' | 'monitor' | 'tablet';

type TResponse = {
  Responsive: {
    isBiggerThan: (breakpoint: BreakpointName) => boolean;
    isSmallerThan: (breakpoint: BreakpointName) => boolean;
  };
};

function useResponsive(): TResponse {
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return {
    Responsive: {
      isBiggerThan: (breakpoint: BreakpointName) => {
        return breakpoints[breakpoint] < width;
      },
      isSmallerThan: (breakpoint: BreakpointName) => {
        return breakpoints[breakpoint] >= width;
      },
    },
  };
}

export default useResponsive;
