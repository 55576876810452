import { isAfterDate, isBeforeDate } from '../../_utils';

import { useGetMonthlyActivities } from './useGetMonthlyActivities';
import { useGetSingleActivities } from './useGetSingleActivities';
import { useGetWeeklyActivities } from './useGetWeeklyActivities';

/**
 * Gets Contentful single, weekly and monthly activities from a certain month
 * @param {Date} date - Date to get month from
 * @returns {TActivity[], boolean} activities, isLoading - List of all activities in concerned month, boolean loading
 */
export function useGetActivities(date: Date) {
  const { data: singleData, isLoading: singleIsLoading } = useGetSingleActivities(date);
  const { data: weeklyData, isLoading: weeklyIsLoading } = useGetWeeklyActivities(date);
  const { data: monthlyData, isLoading: monthlyIsLoading } = useGetMonthlyActivities(date);

  const activities = [...singleData, ...weeklyData, ...monthlyData];

  const sortedActivities = activities?.sort((a, b) => {
    if (isAfterDate(new Date(a.startDate), new Date(b.startDate))) return 1;
    if (isBeforeDate(new Date(a.startDate), new Date(b.startDate))) return -1;
    else return 0;
  });

  return {
    data: sortedActivities,
    isLoading: singleIsLoading || weeklyIsLoading || monthlyIsLoading,
  };
}
