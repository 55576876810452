export type TFormStep = {
  description: string;
  title: string;
};

export const offerFormSteps: TFormStep[] = [
  {
    description: 'FORMS.OFFER.PERSONAL_INFO.DESCRIPTION',
    title: 'FORMS.OFFER.PERSONAL_INFO.TITLE',
  },
  {
    description: 'FORMS.OFFER.CHILD_INFO.DESCRIPTION',
    title: 'FORMS.OFFER.CHILD_INFO.TITLE',
  },
  {
    description: 'FORMS.OFFER.FAMILY_INFO.DESCRIPTION',
    title: 'FORMS.OFFER.FAMILY_INFO.TITLE',
  },
  {
    description: 'FORMS.OFFER.SITUATION_INFO.DESCRIPTION',
    title: 'FORMS.OFFER.SITUATION_INFO.TITLE',
  },
  {
    description: 'FORMS.OFFER.FINAL.DESCRIPTION',
    title: 'FORMS.OFFER.FINAL.TITLE',
  },
];
