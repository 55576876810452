import React from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../../_shared/input/checkbox/CheckboxGroup';
import { TOfferFormStepThreeSchema } from '../../_schemas/offerForm.schema';

type Props = {
  errors: FieldErrors<TOfferFormStepThreeSchema>;
  register: UseFormRegister<TOfferFormStepThreeSchema>;
};

export const FamilyQuery = ({ register, errors }: Props) => {
  const { t } = useTranslation();

  return (
    <CheckboxGroup error={errors.family?.message} label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.TITLE_FAMILY')} name="family">
      <Checkbox
        type="radio"
        {...register('family', { required: true })}
        label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.MOTHER')}
        name="family"
        value="mother"
      />
      <Checkbox
        type="radio"
        value="father"
        {...register('family', { required: true })}
        label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.FATHER')}
        name="family"
      />
      <Checkbox
        type="radio"
        value="motherAndFather"
        {...register('family', { required: true })}
        label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.RADIOBUTTONS_FAMILY.MOTHERANDFATHER')}
        name="family"
      />
      <Checkbox
        type="radio"
        value="other"
        {...register('family', { required: true })}
        label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.OTHER')}
        name="family"
      />
    </CheckboxGroup>
  );
};
