import React from 'react';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { Config } from './config';

import './index.scss';

// Setup sentry error logging
const environmentsWithErrorLogging = ['production', 'staging'];
const needsErrorLogging = Config.sentryDsn && environmentsWithErrorLogging.includes(Config.environment);
if (needsErrorLogging) {
  Sentry.init({
    dsn: Config.sentryDsn,
    environment: Config.environment,
  });
}

ReactDOM.render(
  <BrowserRouter>
    <ApolloProvider client={Config.apolloClient}>
      <App />
    </ApolloProvider>
  </BrowserRouter>,
  document.getElementById('root'),
);
