import React from 'react';

import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../../_shared/input/checkbox/CheckboxGroup';
import { TOfferFormStepThreeSchema } from '../../_schemas/offerForm.schema';

type Props = {
  errors: FieldErrors<TOfferFormStepThreeSchema>;
  register: UseFormRegister<TOfferFormStepThreeSchema>;
};

export const AddressQuery = ({ register, errors }: Props) => {
  const { t } = useTranslation();
  
  return (
    <CheckboxGroup
      error={'isSameAddress' in errors && errors.isSameAddress?.message}
      label={t('FORMS.OFFER.FAMILY_INFO.DETAILS.TITLE_ISSAMEADDRESS')}
      name="isSameAddress"
    >
      <Checkbox
        type="radio"
        {...register('isSameAddress', { required: true })}
        label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.YES')}
        name="isSameAddress"
        value="yes"
      />
      <Checkbox
        type="radio"
        value="no"
        {...register('isSameAddress', { required: true })}
        label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.NO')}
        name="isSameAddress"
      />
    </CheckboxGroup>
  );
};
