import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import GDPR from '../_assets/pdf/GDPR.pdf';
import Icon from '../_shared/icon/Icon';

import './footer.scss';

const Footer: FC = () => {
  const { t } = useTranslation();

  const scrollUp = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
    });
  };

  return (
    <footer className="footer">
      <button className="footer__button" onClick={scrollUp}>
        <Icon name="SvgUnion" />
      </button>

      <div>
        <h4>{t('SHARED.FOOTER.DETAILS.ADDRESS')}</h4>
        <p>{t('SHARED.FOOTER.DETAILS.STREET')}</p>
        <p>{t('SHARED.FOOTER.DETAILS.CITY')}</p>
      </div>

      <div className="footer__divider"></div>

      <div className="footer__links">
        <h4>{t('SHARED.FOOTER.DETAILS.CONTACT')}</h4>
        <a href={`tel:${t('SHARED.FOOTER.DETAILS.PHONE')}`}>{t('SHARED.FOOTER.DETAILS.PHONE')}</a>
        <a href={`mailto:${t('SHARED.FOOTER.DETAILS.MAIL')}`}>{t('SHARED.FOOTER.DETAILS.MAIL')}</a>
      </div>

      <div className="footer__divider"></div>

      <div className="footer__links">
        <a href={GDPR} rel="noopener noreferrer" target="_blank">
          {t('SHARED.FOOTER.GDPR')}
        </a>
        <NavLink to="/disclaimer">{t('SHARED.FOOTER.DISCLAIMER')}</NavLink>
      </div>

      <span className="footer__copyright">{t('SHARED.FOOTER.COPYRIGHT')}</span>
    </footer>
  );
};

export default Footer;
