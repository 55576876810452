import { gql } from '@apollo/client';

export const GetSingleActivitySchema = gql`
  query activityOnceCollection($locale: String!) {
    activityOnceCollection(locale: $locale) {
      items {
        title
        description
        startDate
        duration
        audience
        location
        mail
        phone
      }
    }
  }
`;
