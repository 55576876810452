import React, { FC, useState } from 'react';

import { addMonths, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

import kalenderHeader from '../_assets/images/kalender.jpg';
import { Icon, PageHeader, Spinner } from '../_shared';
import InfoBox from '../_shared/infobox/InfoBox';
import {
  DEFAULT_DAY_OF_MONTH_NUMBER_STRING_FORMAT,
  DEFAULT_LONG_MONTH_STRING_FORMAT,
  DEFAULT_SHORT_DAY_STRING_FORMAT,
  formatDate,
} from '../_utils';

import { useGetActivities } from './_queries';

import './calendar.scss';

const Calendar: FC = () => {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const { t } = useTranslation();
  const { data, isLoading } = useGetActivities(calendarDate);

  return (
    <section className="calendar">
      <PageHeader href="/" imageUrl={kalenderHeader} title={t('SHARED.NAVIGATION.CALENDAR')} />

      <div className="calendar__details">
        <div className="calendar__details__header">
          <button onClick={() => setCalendarDate(prevState => addMonths(prevState, -1))}>
            <Icon name="SvgChevronLeft" size={4}></Icon>
          </button>
          <h4>
            {formatDate(calendarDate, DEFAULT_LONG_MONTH_STRING_FORMAT)} {calendarDate.getFullYear()}
          </h4>
          <button onClick={() => setCalendarDate(prevState => addMonths(prevState, +1))}>
            <Icon name="SvgChevronRight" size={4}></Icon>
          </button>
        </div>
        {isLoading ? (
          <Spinner size="large" />
        ) : data.length ? (
          data.map(({ title, startDate, startTime, description, mail, phone, location, audience }) => (
            <InfoBox
              date={[
                formatDate(parseISO(startDate), DEFAULT_DAY_OF_MONTH_NUMBER_STRING_FORMAT),
                formatDate(parseISO(startDate), DEFAULT_SHORT_DAY_STRING_FORMAT),
              ]}
              key={`${startDate}-${startTime}-${title}`}
              title={title}
            >
              <div className="calendar__details__element">
                <Icon name="SvgInfo" />
                <p>{description}</p>
              </div>
              <div className="calendar__details__element">
                <Icon name="SvgUsers" />
                <p>{audience}</p>
              </div>

              <div className="calendar__details__element">
                <Icon name="SvgClock" />
                <p>{startTime}</p>
              </div>

              <div className="calendar__details__element">
                <Icon name="SvgPin" />
                <div className="calendar__details__element__info">
                  <p>{location}</p>
                </div>
              </div>
              <div className="calendar__details__element">
                <Icon name="SvgPhone" />
                <div className="calendar__details__element__info">
                  <p>{phone}</p>
                  <p>{mail}</p>
                </div>
              </div>
            </InfoBox>
          ))
        ) : (
          <p>{t('CALENDAR.DESCRIPTION')}</p>
        )}
      </div>
    </section>
  );
};
export default Calendar;
