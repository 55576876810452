import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TOfferDetail } from '../_models';
import { serializeContentfulOffer } from '../_serializers';

import { GetOfferByIdSchema } from './_schemas';

export const useGetOffer = (id: string) => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TOfferDetail>(GetOfferByIdSchema, {
    variables: {
      id,
      locale: i18n.language,
    },
  });

  return { isLoading: loading, offer: serializeContentfulOffer(data?.offer) };
};
