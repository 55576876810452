import React, { FC, ChangeEvent, Ref, forwardRef } from 'react';

import classnames from 'classnames';

import InputWrapper, { TInputWrapperProps } from '../InputWrapper';
import './textArea.scss';

export type TProps = TInputWrapperProps & {
  characterLimit?: number;
  normalize?: (value: string) => string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  ref?: Ref<HTMLTextAreaElement>;
  rows?: number;
  value?: string;
};

const TextArea: FC<TProps> = forwardRef(({ onChange, placeholder, value, ...wrapperProps }, ref) => {
  const { disabled, name, error } = wrapperProps;
  return (
    <InputWrapper {...wrapperProps} className={classnames('textarea-wrapper', wrapperProps.className)} refInput={ref}>
      <textarea
        className={classnames('textarea', { error })}
        disabled={disabled}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </InputWrapper>
  );
});

export default TextArea;
