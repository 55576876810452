export class LocalStorage {
  static setItem<T>(key: string, body: T) {
    return localStorage.setItem(key, JSON.stringify(body));
  }

  static getItem(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  static removeItem(key: string) {
    return localStorage.removeItem(key);
  }
}
