import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { THistoryOverview } from '../_models';
import { serializeContentfulHistory } from '../_serializers';

import { GetHistoryCollectionSchema } from './_schemas';

export function useGetHistory() {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<THistoryOverview>(GetHistoryCollectionSchema, {
    variables: { locale: i18n.language },
  });
  const serialized = data?.historyCollection.items
    .map(history => serializeContentfulHistory(history))
    .sort((a, b) => a.title.localeCompare(b.title));

  return { data: serialized, isLoading: loading };
}
