import React, { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { Spinner } from '../../_shared';
import FormWrapper from '../../_shared/form/FormWrapper';
import { useGetOffer } from '../_queries';

import { offerFormSteps } from './_data/offerFormSteps';
import { FormConfirmationStep, OfferStepFour, OfferStepOne, OfferStepThree, OfferStepTwo } from './components';

export const stepToNumberMap = {
  confirmation: 4,
  gezin: 2,
  jezelf: 0,
  kind: 1,
  situatie: 3,
};

export const OfferForm = () => {
  const { id, step, error } = useParams<{ error: string; id: string; step: string }>();
  const stepNumber = stepToNumberMap[step];
  const [currentStep, setCurrentStep] = useState(stepNumber);
  const { offer: { title } = {}, isLoading } = useGetOffer(id);

  useEffect(() => {
    if (stepNumber !== currentStep) setCurrentStep(stepNumber);
  }, [step]);

  if (isLoading) {
    return <Spinner size="large" />;
  }

  const stepProps = { formTitle: title, offerId: id };

  const stepContent = {
    0: <OfferStepOne {...stepProps} />,
    1: <OfferStepTwo {...stepProps} />,
    2: <OfferStepThree {...stepProps} />,
    3: <OfferStepFour {...stepProps} />,
    4: <FormConfirmationStep error={!!error} {...stepProps} />,
  };

  return (
    <div>
      <FormWrapper
        currentStep={currentStep}
        hasError={!!error}
        stepToNumberMap={stepToNumberMap}
        steps={offerFormSteps}
        title={title}
      >
        {stepContent[currentStep]}
      </FormWrapper>
    </div>
  );
};
