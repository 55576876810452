import {
  stepThreeBaseFatherSchema,
  stepThreeFatherAddressFields,
  stepThreeMotherAndFatherSchema,
  stepThreeMotherSchema,
  stepThreeOtherSchema,
} from '../_schemas/offerForm.schema';

export const getFormName = (formTitle: string) => `offerForm${formTitle}`.replace(' ', '');

export const isHomeworkGuidanceForm = (formId: string) => formId === '3hfjGt1MMuEQfaXB3oQUmr';

export const getStepThreeSchema = (
  audience: string,
  isMotherInvolved: string,
  isFatherInvolved: string,
  isSameAddress: string,
) => {
  const schemas = {
    father:
      isMotherInvolved === 'yes'
        ? stepThreeBaseFatherSchema.merge(stepThreeFatherAddressFields.merge(stepThreeMotherSchema))
        : stepThreeBaseFatherSchema.merge(stepThreeFatherAddressFields),
    mother:
      isFatherInvolved === 'yes'
        ? stepThreeMotherSchema.merge(stepThreeBaseFatherSchema.merge(stepThreeFatherAddressFields))
        : stepThreeMotherSchema,
    motherAndFather:
      isSameAddress === 'no'
        ? stepThreeMotherAndFatherSchema.merge(stepThreeFatherAddressFields)
        : stepThreeMotherAndFatherSchema,
    other: stepThreeOtherSchema,
  };

  return schemas[audience] || null;
};
