import React, { FC, ChangeEvent, ReactNode, Ref, forwardRef } from 'react';

import classnames from 'classnames';

import Icon from '../../icon/Icon';
import InputWrapper, { TInputWrapperProps } from '../InputWrapper';
import './inputField.scss';

export type TInputFieldProps = TInputWrapperProps & {
  autoComplete?: string;
  autoFocus?: boolean;
  icon?: ReactNode;
  normalize?: (value: string) => string | number;
  onChange?: (value: ChangeEvent<HTMLInputElement>) => void;
  onClick?: () => void;
  placeholder?: string;
  ref?: Ref<HTMLInputElement>;
  type?: 'email' | 'number' | 'password' | 'text' | 'date' | 'tel';
  value?: string;
  wrapperClassName?: string;
};

const InputField: FC<TInputFieldProps> = forwardRef(
  (
    {
      autoComplete,
      autoFocus,
      onChange,
      onClick = () => {},
      placeholder,
      type = 'text',
      value,
      wrapperClassName,
      ...wrapperProps
    },
    ref,
  ) => {
    const { disabled, name, error } = wrapperProps;
    return (
      <InputWrapper {...wrapperProps} className={classnames('input-field-wrapper', wrapperClassName)} refInput={ref}>
        <input
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          className={classnames('input', { error })}
          disabled={disabled}
          name={name}
          onChange={onChange}
          onClick={onClick}
          placeholder={placeholder}
          type={type}
          value={value}
        />
        <Icon name={name} />
      </InputWrapper>
    );
  },
);

export default InputField;
