import { gql } from '@apollo/client';

export const GetEmployeeCollectionSchema = gql`
  query employees($locale: String!) {
    employeeCollection(locale: $locale) {
      items {
        name
        function
        group
        mail
        picture {
          url
        }
      }
    }
  }
`;
