import React from 'react';

import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Button, Markdown, PageHeader, Spinner } from '../../_shared';
import { LocalStorage } from '../../_utils';
import { useGetOffer } from '../_queries';

export const OfferDetail = () => {
  const { id } = useParams<{ id: string }>();
  const { offer: { picture, title, description, isRequestable } = {}, isLoading } = useGetOffer(id);
  const storedForm = LocalStorage.getItem(`offerForm${title}`);
  const { t } = useTranslation();

  if (isLoading) return <Spinner overlay />;

  const path = storedForm?.currentStep ? storedForm?.currentStep : 'jezelf';

  return (
    <>
      <PageHeader href="/hulpaanbod" imageUrl={picture} title={title} />
      <section className="detail__description">
        <Markdown value={description} />
        {isRequestable && (
          <Button href={`/hulpaanbod/${id}/aanvraag/${path}`}>
            {title} {t('DETAIL_BUTTON')}
          </Button>
        )}
      </section>
    </>
  );
};
