import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { TAboutDetail } from '../_models';
import { serializeContentfulAbout } from '../_serializers';

import { GetAboutDetailSchema } from './_schemas';

export function useGetAboutDetail() {
  const { id } = useParams<{ id: string }>();
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TAboutDetail>(GetAboutDetailSchema, {
    variables: {
      id,
      locale: i18n.language,
    },
  });

  return { data: serializeContentfulAbout(data?.about), isLoading: loading };
}
