import { TContentfulAbout, TAbout } from '../_models';

/**
 * Serializes incoming about data from Contentful.
 * @param {TContentfulAbout} about - About API data from Contentful
 * @returns {TAbout} - Serialized about data
 */
export function serializeContentfulAbout(about: TContentfulAbout): TAbout {
  if (!about) return;

  return {
    description: about.description?.json,
    id: about.sys.id,
    picture: about.picture.url,
    title: about.title,
  };
}
