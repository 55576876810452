import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useSendEmail } from '../../../_hooks/useSendEmail';
import { FormButtons } from '../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../_utils';

type Props = {
  error?: boolean;
};

export const ContactConfirmationStep = ({ error = false }: Props) => {
  const navigate = useNavigate();
  const { sendEmail } = useSendEmail('contact');

  const specialStep = error ? 'confirmationError' : 'confirmation';
  const formName = 'contactForm';
  const storedForm = LocalStorage.getItem(formName);

  const resendMail = async () => {
    const isSuccess = await sendEmail(storedForm);
    if (isSuccess) LocalStorage.removeItem(formName);
    navigate(`/contact/formulier/confirmation/${isSuccess ? '' : 'error'}`, { replace: true });
  };

  return <FormButtons resendMail={resendMail} specialStep={specialStep} />;
};
