import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TEmployeeOverview } from '../_models';
import { serializeContentfulEmployee } from '../_serializers';

import { GetEmployeeCollectionSchema } from './_schemas';

export const useGetEmployee = () => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<TEmployeeOverview>(GetEmployeeCollectionSchema, {
    variables: { locale: i18n.language },
  });

  const serialized = data?.employeeCollection?.items.map(job => serializeContentfulEmployee(job));

  return { data: serialized, isLoading: loading };
};
