import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import Flag from 'react-world-flags';
//TODO: consider alternative for flag, because the import cost of this library is huge


import { languages } from '../../_translations';

const MenuLinks: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <NavLink to="/">{t('SHARED.NAVIGATION.HOME')}</NavLink>
      <NavLink to="/hulpaanbod">{t('SHARED.NAVIGATION.HELP')}</NavLink>
      <NavLink to="/overons">{t('SHARED.NAVIGATION.ABOUT_US')}</NavLink>
      <NavLink to="/kalender">{t('SHARED.NAVIGATION.CALENDAR')}</NavLink>
      <NavLink to="/vacatures">{t('SHARED.NAVIGATION.VACATURES')}</NavLink>
      <NavLink to="/contact">{t('SHARED.NAVIGATION.CONTACT')}</NavLink>

      <button className="flag" onClick={() => i18n.changeLanguage(languages.Dutch)}>
        <Flag code="bel" height="20" />
      </button>
      <button className="flag" onClick={() => i18n.changeLanguage(languages.English)}>
        <Flag code="gbr" height="20" />
      </button>
    </>
  );
};
export default MenuLinks;
