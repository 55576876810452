import { addHours, addMonths, addWeeks } from 'date-fns';

import { formatDate } from '../../_utils';
import { TContentfulActivity, TActivity } from '../_models';

/**
 * Serializes incoming activity data from Contentful.
 * @param {TContentfulActivity} activity - Activity API data from Contentful
 * @returns {TActivity} serialisedActivity - Serialized activity data
 */
export function serializeContentfulActivity(activity: TContentfulActivity, startDate?: Date): TActivity {
  if (!activity) return;
  let date = new Date(activity.startDate);

  if (startDate) date = startDate;
  // Adds +1 hour to convert UTC to Brussels time zone
  addHours(date, 1);
  const time = formatDate(new Date(date.getTime()), 'HH:mm');

  return { ...activity, startDate: date.toISOString(), startTime: time };
}

/**
 * Adds x amount of weeks to start date of the activity. Serializes contentful Activity into TActivity.
 * @param {TContentfulActivity} activity - Activity to add x amount of weeks to
 * @param {number} amountOfWeeksToAdd Amount of weeks to add
 * @returns {TActivity} serialisedActivity - Serialized activity with added weeks
 */
export function serializeContentfulWeeklyActivity(activity: TContentfulActivity, amountOfWeeksToAdd: number): TActivity {
  if (!activity) return;
  const newStartdate = addWeeks(new Date(activity.startDate), amountOfWeeksToAdd);

  return serializeContentfulActivity(activity, newStartdate);
}

/**
 * Adds x amount of months to start date of the activity. Serializes contentful Activity into TActivity.
 * @param {TContentfulActivity} activity - Activity to add x amount of months to
 * @param {number} amountOfWeeksToAdd Amount of months to add
 * @returns {TActivity} serialisedActivity - Serialized activity with added months
 */
export function serializeContentfulMonthlyActivity(activity: TContentfulActivity, amountOfMonthsToAdd: number): TActivity {
  if (!activity) return;
  const newStartdate = addMonths(new Date(activity.startDate), amountOfMonthsToAdd);

  return serializeContentfulActivity(activity, newStartdate);
}
