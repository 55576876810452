import React, { useEffect, useState } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useStoredForm } from '../../../../_hooks/useStoredForm';
import { FormButtons } from '../../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../../_utils';
import { clearFamilyFields, isAccessToStepAllowed } from '../../../../_utils/offerHelpers';
import { offerFormStepThreeDefaultValues } from '../../_data/offerFormData';
import { TResponsibleAdult } from '../../_models/offerForm';
import {
  TOfferFormStepThreeSchema,
  offerFormStepThreeSchema,
  offerFormStepTwoBaseSchema,
  offerFormStepTwoSchema,
} from '../../_schemas/offerForm.schema';
import { getFormName, getStepThreeSchema, isHomeworkGuidanceForm } from '../../_utils/offerFormUtils';

import { AddressFields } from './AddressFields';
import { AddressQuery } from './AddressQuery';
import { FamilyFields } from './FamilyFields';
import { FamilyQuery } from './FamilyQuery';
import { IsInvolvedQuery } from './IsInvolvedQuery';

type Props = {
  formTitle: string;
  offerId: string;
};

const getInitialValues = (formTitle: string) => {
  return {
    ...offerFormStepThreeDefaultValues,
    ...LocalStorage.getItem(getFormName(formTitle)),
  };
};

export const OfferStepThree = ({ offerId, formTitle }: Props) => {
  const stepTwoSchema = isHomeworkGuidanceForm(offerId) ? offerFormStepTwoSchema : offerFormStepTwoBaseSchema;
  const initialValues = getInitialValues(formTitle);
  const navigate = useNavigate();
  const [schema, setSchema] = useState<any>(offerFormStepThreeSchema);

  useEffect(() => {
    if (!isAccessToStepAllowed(stepTwoSchema, initialValues)) {
      navigate(`/hulpaanbod/${offerId}/aanvraag/kind`, { replace: true });
    }
  }, []);

  const {
    handleSubmit,
    watch,
    register,
    setValue,
    formState: { errors },
  } = useForm<TOfferFormStepThreeSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(schema),
  });

  const currentFormValues = watch();
  const { updateForm } = useStoredForm<TOfferFormStepThreeSchema>(currentFormValues, getFormName(formTitle), 'gezin');

  const defaultProps = { errors, register };

  const handlePrevious = () => {
    navigate(`/hulpaanbod/${offerId}/aanvraag/kind`, { replace: true });
  };

  const handleNext: SubmitHandler<TOfferFormStepThreeSchema> = () => {
    navigate({ pathname: `/hulpaanbod/${offerId}/aanvraag/situatie` });
  };

  const responsibleAdult = watch('family');
  const isMotherInvolved = watch('isMotherInvolved');
  const isFatherInvolved = watch('isFatherInvolved');
  const isSameAddress = watch('isSameAddress');

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  useEffect(() => {
    const shouldClearFatherFields =
      responsibleAdult !== 'father' && responsibleAdult !== 'motherAndFather' && isFatherInvolved !== 'yes';
    const shouldClearMotherFields =
      responsibleAdult !== 'mother' && responsibleAdult !== 'motherAndFather' && isMotherInvolved !== 'yes';
    const shouldClearOtherFields = responsibleAdult !== 'other';

    if (shouldClearFatherFields) clearFamilyFields(setValue, 'father');
    if (shouldClearMotherFields) clearFamilyFields(setValue, 'mother');
    if (shouldClearOtherFields) clearFamilyFields(setValue, 'other');
  }, [responsibleAdult, isFatherInvolved, isMotherInvolved]);

  useEffect(() => {
    setSchema(getStepThreeSchema(responsibleAdult, isMotherInvolved, isFatherInvolved, isSameAddress));
  }, [responsibleAdult, isMotherInvolved, isFatherInvolved, isSameAddress]);

  const shouldRenderIsSameAddressCheckbox = responsibleAdult === 'motherAndFather';

  const renderFields = (responsibleAdult: TResponsibleAdult) => {
    switch (responsibleAdult) {
      case 'mother':
        return (
          <>
            <FamilyFields {...defaultProps} responsibleAdult={'mother'} />
            <IsInvolvedQuery responsibleAdult="mother" {...defaultProps} />
          </>
        );
      case 'father':
        return (
          <>
            <FamilyFields {...defaultProps} responsibleAdult={'father'} />
            <IsInvolvedQuery responsibleAdult="father" {...defaultProps} />
          </>
        );
      case 'motherAndFather':
        return (
          <>
            <FamilyFields {...defaultProps} responsibleAdult={'mother'} />
            <FamilyFields {...defaultProps} hideAddressFields="yes" responsibleAdult={'father'} />
          </>
        );
      case 'other':
        return <FamilyFields {...defaultProps} responsibleAdult={'other'} />;
      default:
        return null;
    }
  };

  const renderOppositeAdultFields = (responsibleAdult: TResponsibleAdult) => {
    if (responsibleAdult === 'motherAndFather' || responsibleAdult === 'other') {
      return null;
    }
    if (responsibleAdult === 'mother' && isFatherInvolved === 'yes') {
      return <FamilyFields {...defaultProps} responsibleAdult={'father'} />;
    }
    if (responsibleAdult === 'father' && isMotherInvolved === 'yes') {
      return <FamilyFields {...defaultProps} responsibleAdult={'mother'} />;
    }
  };

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <FamilyQuery {...defaultProps} />
      {renderFields(responsibleAdult as TResponsibleAdult)}
      {renderOppositeAdultFields(responsibleAdult as TResponsibleAdult)}
      {shouldRenderIsSameAddressCheckbox && <AddressQuery {...defaultProps} />}
      {responsibleAdult === 'motherAndFather' && isSameAddress === 'no' && (
        <AddressFields {...defaultProps} responsibleAdult="father" />
      )}
      <FormButtons handlePrevious={handlePrevious} />
    </form>
  );
};
