import { useQuery } from '@apollo/client';
import { differenceInMonths } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { addActivityWhenNotOnExceptionDate, isInCurrentMonth, isStartdateBeforeAndEnddateAfter } from '../../_utils';
import { TActivity, TMonthlyActivities } from '../_models';
import { serializeContentfulActivity, serializeContentfulMonthlyActivity } from '../_serializers';

import { GetMonthlyActivitySchema } from './_schemas';

/**
 * Gets all Contentful monthly recurring activities from a certain month
 * @param {Date} date - Date to get month from
 * @returns {TActivity[]} monthlyActivities, isLoading - List of all monthly recurring activities in concerned month, boolean loading
 */
export function useGetMonthlyActivities(date: Date) {
  const { i18n } = useTranslation();
  const monthlyList: TActivity[] = [];
  const dateToCompare = new Date(date);
  const { data, loading } = useQuery<TMonthlyActivities>(GetMonthlyActivitySchema, {
    variables: { locale: i18n.language },
  });

  data?.activityMonthlyCollection.items.forEach(monthlyActivity => {
    const serializedInitActivity = serializeContentfulActivity(monthlyActivity);
    const activityStartDate = new Date(monthlyActivity.startDate);
    const activityEndDate = new Date(monthlyActivity.endDate);
    const exceptionDates = serializedInitActivity?.exceptionDates;

    // Initial activity startdate starts before date to compare and enddate ends after date to compare check
    if (isStartdateBeforeAndEnddateAfter(activityStartDate, activityEndDate, dateToCompare)) {
      // Initial activity startdate starts in month of date to compare check
      if (isInCurrentMonth(activityStartDate, dateToCompare)) {
        addActivityWhenNotOnExceptionDate(serializedInitActivity, monthlyList, exceptionDates);
      }

      const amountMonths = differenceInMonths(activityEndDate, activityStartDate);

      // Adds x = (key + 1) amount of months to newly made activity and serializes it
      Array.from(Array(amountMonths)).map((_value, key) => {
        const serializedActivity = serializeContentfulMonthlyActivity(monthlyActivity, key + 1);
        const serializedStartDate = new Date(serializedActivity.startDate);

        // Serialized activity startdate starts in month of date to compare check
        if (isInCurrentMonth(serializedStartDate, dateToCompare)) {
          // Serialized activity startdate is on exceptiondate check
          addActivityWhenNotOnExceptionDate(serializedActivity, monthlyList, exceptionDates);
        }
      });
    }
  });

  return { data: monthlyList, isLoading: loading };
}
