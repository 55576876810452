import React, { FC } from 'react';

import { NavLink } from 'react-router-dom';

import { getImage } from '../../_utils/imageHelpers';
import Icon from '../icon/Icon';
import './pageHeader.scss';

type TProps = {
  href: string;
  imageUrl: string;
  title: string;
};

const PageHeader: FC<TProps> = ({ imageUrl, title, href }) => (
  <div
    className="page-header"
    style={{
      backgroundImage: `url(${getImage(imageUrl, 900, 900)})`,
    }}
  >
    <NavLink className="page-header__link" to={href}>
      <Icon name="SvgUnion" size={1.6} />
    </NavLink>
    <h2>{title}</h2>
  </div>
);

export default PageHeader;
