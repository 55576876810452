import React from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Icon } from '..';

type Props = {
  handlePrevious?: () => void;
  resendMail?: () => void;
  specialStep?: 'first' | 'last' | 'confirmation' | 'confirmationError';
};

export const FormButtons = ({ specialStep, handlePrevious, resendMail }: Props) => {
  const { t } = useTranslation();

  const renderButtons = () => {
    if (specialStep === 'confirmation') {
      return <Button href="/">{t('FORMS.CONTACT.FINAL.BACKTOHOME')}</Button>;
    }

    if (specialStep === 'confirmationError') {
      return <Button onClick={resendMail}>{t('FORMS.OFFER.FINAL.RESEND_EMAIL')}</Button>;
    }

    return (
      <>
        <Button className="form__button-container__button" onClick={handlePrevious} theme="negative">
          {specialStep === 'first' ? (
            t('SHARED.BUTTONS.CANCEL')
          ) : (
            <>
              <Icon className="icon__left" name="SvgChevronLeft" />
              {t('FORMS.CONTACT.BUTTONS.PREVIOUS')}
            </>
          )}
        </Button>

        <Button className="form__button-container__button" type="submit">
          {specialStep === 'last' ? (
            <>
              {t('FORMS.CONTACT.BUTTONS.SEND')} <Icon className="icon__right" name="SvgMail" />
            </>
          ) : (
            <>
              {t('FORMS.CONTACT.BUTTONS.NEXT')} <Icon className="icon__right" name="SvgChevronRight" />
            </>
          )}
        </Button>
      </>
    );
  };

  return (
    <div className="form__actions">
      <div className="form__button-container">{renderButtons()}</div>
    </div>
  );
};
