import React, { FC } from 'react';

import { Document } from '@contentful/rich-text-types';
import classnames from 'classnames';

import { Markdown } from '../../../_shared';
import './timeline.scss';

type TProps = {
  description: Document;
  isVisible: boolean;
  onClick: () => void;
  title: string;
};

const Timeline: FC<TProps> = ({ title, description, isVisible, onClick }) => (
  <div className="timeline">
    <div className={classnames('dot', isVisible && 'timeline__visible')} />
    <div className="timeline__heading">
      <button onClick={onClick}>
        <h4 className={classnames(isVisible && 'timeline__heading__visible')}>{title}</h4>
      </button>
      {isVisible && <Markdown value={description} />}
    </div>
  </div>
);

export default Timeline;
