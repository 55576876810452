import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import header from '../../_assets/images/dekleinevoscontact.jpg';
import { Icon, PageHeader } from '../../_shared';
import { Config } from '../../config';
import './detailDkv.scss';

export const DetailDkv: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <PageHeader href="/contact" imageUrl={header} title={t('CONTACT.DKV.TITLE')} />
      <section className="contact__detail__description">
        <h4>{t('CONTACT.TITLES.INFO')}</h4>
        <ul>
          <li>
            <a href={`tel:${t('CONTACT.DKV.INFO.TEL')}`}>{t('CONTACT.DKV.INFO.TEL')}</a>
          </li>
          <li>
            <a href={`mailto:${t('CONTACT.DKV.INFO.MAIL')}`}>{t('CONTACT.DKV.INFO.MAIL')}</a>
          </li>
        </ul>
        <ul>
          <li>{t('CONTACT.DKV.INFO.ADDRESS.TITLE')}</li>
          <li>{t('CONTACT.DKV.INFO.ADDRESS.NAME')}</li>
          <li>{t('CONTACT.DKV.INFO.ADDRESS.STREET')}</li>
          <li>{t('CONTACT.DKV.INFO.ADDRESS.CITY')}</li>
        </ul>
        <h4>{t('CONTACT.TITLES.REACHABILITY')}</h4>
        <p>{t('CONTACT.DKV.INFO.REACHABILITY.DESCRIPTION')}</p>
        <ul>
          <li>{t('CONTACT.DKV.INFO.REACHABILITY.TRAM')}</li>
          <li>{t('CONTACT.DKV.INFO.REACHABILITY.BUS')}</li>
        </ul>
        <a href={Config.deKleineVosMaps} rel="noreferrer" target="_blank">
          {t('SHARED.BUTTONS.OPENING_IN_MAPS')} <Icon name="SvgChevronRight" size={3} />
        </a>
        <h4>{t('CONTACT.TITLES.HOURS')}</h4>
        <table>
          <tbody>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.MONDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKDAY')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.TUESDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKDAY')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.WEDNESDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKDAY')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.THURSDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKDAY')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.FRIDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKDAY')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.SATURDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKEND')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.SUNDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKEND')}</td>
            </tr>
            <tr>
              <td>{t('CONTACT.DKV.INFO.DAYS.HOLIDAY')}</td>
              <td>{t('CONTACT.DKV.INFO.HOURS.WEEKEND')}</td>
            </tr>
          </tbody>
        </table>
      </section>
    </>
  );
};
