import { gql } from '@apollo/client';

export const GetOfferByIdSchema = gql`
  query offer($id: String!, $locale: String!) {
    offer(id: $id, locale: $locale) {
      sys {
        id
      }
      title
      description {
        json
      }
      ageCategory
      isRequestable
      picture {
        url
      }
      formId
    }
  }
`;
