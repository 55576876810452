import { format, isAfter, isBefore, isValid } from 'date-fns';
import { nl, enUS } from 'date-fns/locale';

import { languages } from '../_translations';
import i18n from '../_translations/I18n';

export const DEFAULT_DATE_STRING_FORMAT = 'dd/MM/yyyy HH:mm';
export const DEFAULT_TIME_STRING_FORMAT = 'HH:mm';
export const DEFAULT_DAY_OF_MONTH_NUMBER_STRING_FORMAT = 'dd';
export const DEFAULT_SHORT_DAY_STRING_FORMAT = 'E';
export const DEFAULT_LONG_MONTH_STRING_FORMAT = 'MMMM';

function getDateFnsLanguage() {
  switch (i18n.language) {
    case languages.Dutch:
      return nl;
    case languages.English:
      return enUS;
    default:
      return nl;
  }
}

export function formatDate(date: Date, formatString = DEFAULT_DATE_STRING_FORMAT): string {
  if (!isValid(date)) return null;
  return format(date, formatString, { locale: getDateFnsLanguage() });
}

export function isAfterDate(date: Date, minDate: Date): boolean {
  return isAfter(date, minDate);
}

export function isBeforeDate(date: Date, maxDate: Date): boolean {
  return isBefore(date, maxDate);
}

export function parseDate(value: unknown): Date | null {
  // Ensure the value can be converted to a string
  if (typeof value !== 'string' && typeof value !== 'number') {
    return null;
  }

  const parts = String(value).split('-');

  // Ensure the string splits into exactly three parts
  if (parts.length !== 3) {
    return null;
  }

  const year = Number(parts[0]);
  const month = Number(parts[1]);
  const day = Number(parts[2]);

  // Ensure the parts are convertible to numbers and represent a valid date
  if (isNaN(year) || isNaN(month) || isNaN(day) || month < 1 || month > 12 || day < 1 || day > 31) {
    return null;
  }

  const date = new Date(year, month - 1, day);

  // Ensure the date was constructed correctly
  if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
    return null;
  }

  return date;
}
