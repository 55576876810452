import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { TFaq } from '../_models/faq';

import { GetFaq } from './_schemas';

export const useGetFaq = (audience: string) => {
  const { i18n } = useTranslation();
  const { data, loading } = useQuery<{
    faqCollection: {
      items: TFaq[];
    };
  }>(GetFaq, {
    variables: {
      audience,
      locale: i18n.language,
    },
  });

  return { data: data?.faqCollection.items, isLoading: loading };
};
