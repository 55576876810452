import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useSendEmail } from '../../../_hooks/useSendEmail';
import { useStoredForm } from '../../../_hooks/useStoredForm';
import { TextArea } from '../../../_shared';
import { FormButtons } from '../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../_utils';
import { isAccessToStepAllowed } from '../../../_utils/offerHelpers';
import { offerFormStepFourDefaultValues } from '../_data/offerFormData';
import {
  TOfferFormStepFourSchema,
  offerFormStepFourDefaultSchema,
  offerFormStepFourSchoolSchema,
  offerFormStepThreeSchema,
} from '../_schemas/offerForm.schema';
import { getFormName, isHomeworkGuidanceForm } from '../_utils/offerFormUtils';

type Props = {
  formTitle: string;
  offerId: string;
};

const getInitialValues = (formTitle: string) => {
  return {
    ...offerFormStepFourDefaultValues,
    ...LocalStorage.getItem(getFormName(formTitle)),
  };
};

export const OfferStepFour = ({ offerId, formTitle }: Props) => {
  const initialValues = getInitialValues(formTitle);
  const shouldRenderSchoolFields = isHomeworkGuidanceForm(offerId);

  useEffect(() => {
    if (!isAccessToStepAllowed(offerFormStepThreeSchema, initialValues)) {
      navigate(`/hulpaanbod/${offerId}/aanvraag/gezin`, { replace: true });
    }
  }, []);

  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TOfferFormStepFourSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(shouldRenderSchoolFields ? offerFormStepFourSchoolSchema : offerFormStepFourDefaultSchema),
  });
  const navigate = useNavigate();
  const currentFormValues = watch();
  const { t } = useTranslation();
  const { sendEmail } = useSendEmail('offer');
  const { updateForm, storedForm, clearForm } = useStoredForm<TOfferFormStepFourSchema>(
    currentFormValues,
    getFormName(formTitle),
    'situatie',
  );

  const handlePrevious = () => {
    navigate(`/hulpaanbod/${offerId}/aanvraag/gezin`, { replace: true });
  };

  const handleNext: SubmitHandler<TOfferFormStepFourSchema> = async () => {
    const isSuccess = await sendEmail(storedForm, formTitle);
    if (isSuccess) clearForm();
    navigate({ pathname: `/hulpaanbod/${offerId}/aanvraag/confirmation/${isSuccess ? '' : 'error'}` });
  };

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      {shouldRenderSchoolFields ? (
        <>
          <h5>{t('FORMS.OFFER.SITUATION_INFO.STRENGTHS.TITLE')}</h5>
          <TextArea
            {...register('answer5')}
            error={errors.answer5?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.STRENGTHS.SCHOOL')}
            name="answer5"
          />
          <h5>{t('FORMS.OFFER.SITUATION_INFO.CONCERNS.TITLE')}</h5>
          <TextArea
            {...register('answer6')}
            error={errors.answer6?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.CONCERNS.SCHOOL')}
            name="answer6"
          />
          <h5>{t('FORMS.OFFER.SITUATION_INFO.WISHES.TITLE')}</h5>
          <TextArea
            {...register('answer7')}
            error={errors.answer7?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.WISHES.DEFAULT')}
            name="answer7"
          />
        </>
      ) : (
        <>
          <h5>{t('FORMS.OFFER.SITUATION_INFO.STRENGTHS.TITLE')}</h5>
          <TextArea
            {...register('answer1')}
            error={errors.answer1?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.STRENGTHS.DEFAULT')}
            name="answer1"
          />
          <TextArea
            {...register('answer2')}
            error={errors.answer2?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.STRENGTHS.DEFAULT_2')}
            name="answer2"
          />
          <h5>{t('FORMS.OFFER.SITUATION_INFO.CONCERNS.TITLE')}</h5>
          <TextArea
            {...register('answer3')}
            error={errors.answer3?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.CONCERNS.DEFAULT')}
            name="answer3"
          />
          <h5>{t('FORMS.OFFER.SITUATION_INFO.WISHES.TITLE')}</h5>
          <TextArea
            {...register('answer4')}
            error={errors.answer4?.message}
            label={t('FORMS.OFFER.SITUATION_INFO.WISHES.DEFAULT')}
            name="answer4"
          />
        </>
      )}
      <FormButtons handlePrevious={handlePrevious} specialStep="last" />
    </form>
  );
};
