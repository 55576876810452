import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useStoredForm } from '../../../_hooks/useStoredForm';
import { InputField } from '../../../_shared';
import { FormButtons } from '../../../_shared/form/FormButtons';
import Checkbox from '../../../_shared/input/checkbox/Checkbox';
import CheckboxGroup from '../../../_shared/input/checkbox/CheckboxGroup';
import { LocalStorage } from '../../../_utils';
import { clearCaregiverFields } from '../../../_utils/offerHelpers';
import { offerFormStepOneDefaultValues } from '../_data/offerFormData';
import { TOfferFormStepOneSchema, offerFormStepOneSchema } from '../_schemas/offerForm.schema';
import { getFormName } from '../_utils/offerFormUtils';

type Props = {
  formTitle: string;
  offerId: string;
};

const getInitialValues = (formTitle: string) => {
  return {
    ...offerFormStepOneDefaultValues,
    ...LocalStorage.getItem(getFormName(formTitle)),
  };
};

export const OfferStepOne = ({ offerId, formTitle }: Props) => {
  const {
    watch,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<TOfferFormStepOneSchema>({
    defaultValues: getInitialValues(formTitle),
    resolver: zodResolver(offerFormStepOneSchema),
  });
  const currentFormValues = watch();
  const { setForm, clearForm } = useStoredForm<TOfferFormStepOneSchema>(currentFormValues, getFormName(formTitle), 'jezelf');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldRenderCaregiverFields = watch('audience') === 'caregiver';

  const handlePrevious = () => {
    clearForm();
    navigate({ pathname: `/hulpaanbod/${offerId}` });
  };

  const handleNext: SubmitHandler<TOfferFormStepOneSchema> = () => {
    navigate({ pathname: `/hulpaanbod/${offerId}/aanvraag/kind` });
  };

  useEffect(() => {
    setForm({ ...currentFormValues, currentStep: 'jezelf' });
  }, [currentFormValues]);

  useEffect(() => {
    if (!shouldRenderCaregiverFields) clearCaregiverFields(setValue);
  }, [shouldRenderCaregiverFields]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <CheckboxGroup
        error={errors.audience?.message}
        label={t('FORMS.OFFER.PERSONAL_INFO.DETAILS.TITLE_AUDIENCE')}
        name="audience"
      >
        <Checkbox
          type="radio"
          {...register('audience', { required: true })}
          label={t('FORMS.OFFER.PERSONAL_INFO.DETAILS.RADIOBUTTONS_AUDIENCE.PARENT')}
          name="audience"
          value="parent"
        />
        <Checkbox
          type="radio"
          value="caregiver"
          {...register('audience', { required: true })}
          label={t('FORMS.OFFER.PERSONAL_INFO.DETAILS.RADIOBUTTONS_AUDIENCE.CAREGIVER')}
          name="audience"
        />
      </CheckboxGroup>

      {shouldRenderCaregiverFields && (
        <>
          <InputField
            {...register('caregiverFirstName', { required: true })}
            error={'caregiverFirstName' in errors && errors.caregiverFirstName?.message}
            label={t('FORMS.CONTACT.INFO.FIRSTNAME')}
            name="caregiverFirstName"
          />
          <InputField
            {...register('caregiverLastName', { required: true })}
            error={'caregiverLastName' in errors && errors.caregiverLastName?.message}
            label={t('FORMS.CONTACT.INFO.LASTNAME')}
            name="caregiverLastName"
          />
          <InputField
            {...register('caregiverDepartment', { required: true })}
            error={'caregiverDepartment' in errors && errors.caregiverDepartment?.message}
            label={t('FORMS.OFFER.PERSONAL_INFO.DETAILS.CAREGIVER_DEPARTMENT')}
            name="caregiverDepartment"
          />
          <InputField
            {...register('caregiverEmail', { required: true })}
            error={'caregiverEmail' in errors && errors.caregiverEmail?.message}
            label={t('FORMS.OFFER.SHARED.EMAIL')}
            name="caregiverEmail"
          />
          <InputField
            {...register('caregiverPhone', { required: true })}
            error={'caregiverPhone' in errors && errors.caregiverPhone?.message}
            label={t('FORMS.OFFER.SHARED.PHONE')}
            name="caregiverPhone"
          />
          <CheckboxGroup
            error={'isDiscussed' in errors && errors.isDiscussed?.message}
            label={t('FORMS.OFFER.PERSONAL_INFO.DETAILS.TITLE_ISDISCUSSED')}
            name="isDiscussed"
          >
            <Checkbox
              type="radio"
              value="no"
              {...register('isDiscussed', { required: true })}
              label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.NO')}
              name="isDiscussed"
            />
            <Checkbox
              type="radio"
              {...register('isDiscussed', { required: true })}
              label={t('FORMS.OFFER.SHARED.BOOLEAN_OPTIONS.YES')}
              name="isDiscussed"
              value="yes"
            />
          </CheckboxGroup>
        </>
      )}
      <FormButtons handlePrevious={handlePrevious} specialStep="first" />
    </form>
  );
};
