import React, { FC } from 'react';

import { useTranslation } from 'react-i18next';

import cardImageHistory from '../../_assets/images/History_dkv.jpg';
import image from '../../_assets/images/about.jpg';
import cardImageWorkers from '../../_assets/images/bedanking.jpg';
import { Card, PageHeader, Spinner } from '../../_shared';
import { useGetAbout } from '../_queries';

import './overview.scss';

export const Overview: FC = () => {
  const { data, isLoading } = useGetAbout();
  const { t } = useTranslation();

  return (
    <section className="overview">
      <PageHeader href="/" imageUrl={image} title={t('SHARED.NAVIGATION.ABOUT_US')} />
      {isLoading ? (
        <Spinner />
      ) : (
        <ul>
          {data?.map(({ id, picture, title }) => (
            <li key={id}>
              <Card href={`/overons/${id}`} imageUrl={picture} title={title} />
            </li>
          ))}
          <li>
            <Card href={'/overons/geschiedenis'} imageUrl={cardImageHistory} title={t('HISTORY')} />
          </li>
          <li>
            <Card href={'/overons/medewerkers'} imageUrl={cardImageWorkers} title={t('WORKERS.TITLE')} />
          </li>
        </ul>
      )}
    </section>
  );
};
