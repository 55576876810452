import React, { useEffect } from 'react';

import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { useStoredForm } from '../../../_hooks/useStoredForm';
import { TextArea } from '../../../_shared';
import { FormButtons } from '../../../_shared/form/FormButtons';
import { LocalStorage } from '../../../_utils';
import { contactFormStepOneDefaultValues } from '../_data/contactFormData';
import { TContactFormStepOneSchema, stepOneSchema } from '../_schemas/contactForm.schema';

const getInitialValues = () => {
  return {
    ...contactFormStepOneDefaultValues,
    ...LocalStorage.getItem('contactForm'),
  };
};

export const ContactStepOne = () => {
  const initialValues = getInitialValues();
  const {
    watch,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<TContactFormStepOneSchema>({
    defaultValues: initialValues,
    resolver: zodResolver(stepOneSchema),
  });
  const currentFormValues = watch();
  const navigate = useNavigate();

  const { updateForm, clearForm } = useStoredForm<TContactFormStepOneSchema>(currentFormValues, 'contactForm', 'bericht');

  const handlePrevious = () => {
    clearForm();
    navigate({ pathname: '/contact' });
  };

  const handleNext: SubmitHandler<TContactFormStepOneSchema> = () => {
    navigate({ pathname: '/contact/formulier/hoedanigheid' });
  };

  useEffect(() => {
    updateForm();
  }, [currentFormValues]);

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <TextArea {...register('message')} error={errors.message?.message} name="message" />
      <FormButtons handlePrevious={handlePrevious} specialStep="first" />
    </form>
  );
};
