import React, { FC, Suspense, useEffect } from 'react';

import emailjs from '@emailjs/browser';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Menu from './_routing/menu/Menu';
import { Contact, Spinner } from './_shared';
import { Detail as AboutDetail } from './about/detail/Detail';
import HistoryDkv from './about/history/HistoryDkv';
import { Overview as AboutOverview } from './about/overview/Overview';
import Workers from './about/workers/Workers';
import Calendar from './calendar/Calendar';
import { Config } from './config';
import { ContactForm } from './contact/contactForm/ContactForm';
import { ContactOverview } from './contact/contactOverview/ContactOverview';
import { DetailDkv } from './contact/detailDkv/DetailDkv';
import { DetailKakelbont } from './contact/detailKakelbont/DetailKakelbont';
import Disclaimer from './disclaimer/Disclaimer';
import Faq from './faq/Faq';
import Footer from './footer/footer';
import Home from './home/home';
import { Detail as JobsDetail } from './jobs/detail/Detail';
import { Overview as JobsOverview } from './jobs/overview/Overview';
import { OfferDetail } from './offers/detail/OfferDetail';
import { OfferForm } from './offers/offerForm/OfferForm';
import { OffersOverview } from './offers/overview/OffersOverview';

const App: FC = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    emailjs.init(Config.emailjs.publicKey);
  }, []);
  return (
    <Suspense fallback={<Spinner overlay size="large" />}>
      <Menu />
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<Faq audience="Hulpverleners" />} path="/faq/hulpverleners" />
        <Route element={<Faq audience="Ouders" />} path="/faq/ouders" />
        <Route element={<Faq audience="Kinderen" />} path="/faq/kinderen" />
        <Route element={<OffersOverview />} path="/hulpaanbod" />
        <Route element={<OfferDetail />} path="/hulpaanbod/:id/" />
        <Route element={<OfferForm />} path="/hulpaanbod/:id/aanvraag/:step/:error?" />
        <Route element={<ContactOverview />} path="/contact" />
        <Route element={<DetailDkv />} path="/contact/dekleinevos" />
        <Route element={<DetailKakelbont />} path="/contact/kakelbont" />
        <Route element={<ContactForm />} path="/contact/formulier/:step/:error?" />
        <Route element={<HistoryDkv />} path="/overons/geschiedenis" />
        <Route element={<Workers />} path="/overons/medewerkers" />
        <Route element={<AboutOverview />} path="/overons" />
        <Route element={<AboutDetail />} path="/overons/:id" />
        <Route element={<Calendar />} path="/kalender" />
        <Route element={<JobsOverview />} path="/vacatures" />
        <Route element={<JobsDetail />} path="/vacatures/:id" />
        <Route element={<Disclaimer />} path="/disclaimer" />
        <Route element={<Navigate to="/" />} />
      </Routes>
      <Contact />
      <Footer />
    </Suspense>
  );
};

export default App;
